import classNames from 'classnames/bind';
import React from 'react';
import { useParams } from 'react-router';

import Chapters from 'components/Course/Shared/Progress/Chapters/Chapters';
import CourseProgress from 'components/Course/Shared/Progress/CourseProgress/CourseProgress';
import QuestionEdit from 'components/Course/Shared/QuestionEdit/QuestionEdit';
import Agenda from 'components/Shared/Agenda/Agenda';
import CourseContent from 'components/Shared/CourseContent';
import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryCourseProgress } from 'gql/queries/course';
import { useURLToStateQuestionCommentOpenQuestion } from 'hooks/useURLToState';

import styles from './Progress.module.scss';

const cx = classNames.bind(styles);

const Progress = () => {
  const { courseId } = useParams();

  const { isLoading, data } = useQueryCourseProgress({
    variables: {
      courseId,
    },
  });

  useURLToStateQuestionCommentOpenQuestion(isLoading, data);

  const course = data?.course;

  const hasStatistics =
    course &&
    []
      .concat(course.questions, ...course.chapters.map((c) => c.questions))
      .filter((q) => q.studyStats.attempted).length > 0;

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {course.deadline && (
        <div className={cx('agenda')}>
          <Agenda course={course} isSingle />
        </div>
      )}
      {hasStatistics && (
        <>
          <CourseProgress
            className={cx('course-progress')}
            course={course}
            isStudent
          />
          {course.chapters.length !== 0 && (
            <Chapters
              className={cx('chapters')}
              chapters={course.chapters}
              course={course}
              isStudent
            />
          )}
        </>
      )}
      <CourseContent course={course} />
      <QuestionEdit course={course} />
    </>
  );
};

export default Progress;
