import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { mutationEditorStudentDelete } from 'gql/mutations/editor';
import Button from 'uikit/Button';

import styles from './StudentCard.module.scss';

const cx = classNames.bind(styles);

const StudentCard = ({ className = null, course, student }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students/Finder/StudentCard',
  });

  function handleStudentDelete() {
    mutationEditorStudentDelete(student);
  }

  return (
    <div className={cx('container', className)}>
      <div
        aria-hidden="true"
        className={cx('logo')}
        style={{
          backgroundImage: `url("${student.picture}")`,
        }}
      >
        {!student.picture &&
          `${student.lastName && student.lastName[0]}${
            student.firstName && student.firstName[0]
          }`.toUpperCase()}
      </div>
      <h3 className={cx('title')}>
        {`${student.lastName.toUpperCase()}, ${student.firstName}`}
      </h3>
      {!course.isExam && (
        <Button
          as={Link}
          className={cx('action')}
          icon="chart-bar"
          size="small"
          to={{
            pathname: `/course/${course.id}/statistics`,
            state: {
              type: 'student',
              studentId: student.id,
            },
          }}
          tooltip={t('statistics')}
        />
      )}
      <Button
        className={cx('action')}
        icon="trash-alt"
        onClick={handleStudentDelete}
        size="small"
        tooltip={t('delete-student')}
        variant="regular"
      />
    </div>
  );
};

StudentCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
};

export default StudentCard;
