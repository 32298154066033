import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import {
  mutationEditorClear,
  mutationEditorQuestionCommentsChange,
} from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryQuestionComments } from 'gql/queries/question';
import { useURLToStateQuestionCommentOpenComment } from 'hooks/useURLToState';
import { getQuestionRecto, getQuestionVerso } from 'questions';
import Button from 'uikit/Button';
import SubPage from 'uikit/SubPage/SubPage';

import Comment from './Comment/Comment';
import styles from './Comments.module.scss';

const cx = classNames.bind(styles);

const Comments = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Shared/Comment/Comments',
  });

  const {
    data: {
      editor: { status: editorStatus, questionEditing, commentSelected },
    },
  } = useQueryLocalEditor();

  const isVisible = editorStatus === EDITOR_STATUSES.QUESTION_COMMENTS;

  const { isLoading, data } = useQueryQuestionComments({
    variables: {
      questionId: questionEditing?.id,
    },
    skip: !isVisible,
  });

  function handleThreadClose() {
    mutationEditorQuestionCommentsChange(null);
  }

  const RectoQuestion = getQuestionRecto(questionEditing?.type);

  const VersoQuestion = getQuestionVerso(questionEditing?.type);

  const initialValues = {
    answer: RectoQuestion?.defaultAnswer(questionEditing),
  };

  const question = commentSelected?.studyAnswer
    ? {
        ...questionEditing,
        ...commentSelected.studyAnswer,
      }
    : questionEditing;

  useURLToStateQuestionCommentOpenComment(isLoading, data);

  const unSortedComments = data?.question.comments
    ? [...data?.question.comments]
    : [];

  const comments = unSortedComments.toSorted((a, b) =>
    a.isRead === b.isRead ? 0 : b.isRead ? -1 : 1,
  );

  return (
    <SubPage
      isOpen={isVisible}
      onExit={mutationEditorClear}
      subtitle={course.title}
      title={t('comments')}
    >
      <div className={cx('container')}>
        <aside className={cx('preview')}>
          <p className={cx('explanation-preview')}>
            {t('explanation-preview')}
          </p>
          <div className={cx('question')}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={() => null}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {() => (
                <Form>
                  {VersoQuestion && question.result && (
                    <VersoQuestion isDisabled question={question} />
                  )}
                  {RectoQuestion && !question.result && (
                    <RectoQuestion isDisabled question={question} />
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </aside>
        <main className={cx('comments')}>
          {comments?.length !== 0 ? (
            <>
              {!!commentSelected && (
                <div className={cx('thread')}>
                  <Button
                    aria-label={t('aria-close-thread')}
                    onClick={handleThreadClose}
                    icon="times"
                    intent="white"
                  />
                  <p className={cx('thread-title')}>{t('thread')}</p>
                </div>
              )}
              {comments.map((comment) => (
                <Comment
                  className={cx('comment')}
                  course={course}
                  comment={comment}
                  key={comment.id}
                />
              ))}
            </>
          ) : (
            <p>{t('no-comments')}</p>
          )}
        </main>
      </div>
    </SubPage>
  );
};

Comments.propTypes = {
  course: PropTypes.object.isRequired,
};

export default Comments;
