import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useQueryMe } from 'gql/queries/me';
import { mutationAppLanguageUpdate } from 'gql/mutations/app';
import { trackEvent } from 'lib/tracking';
import SubPage from 'uikit/SubPage/SubPage';

import Institution from './Institution/Institution';
import Marketing from './Marketing/Marketing';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import Profile from './Profile/Profile';
import Role from './Role/Role';
import Sector from './Sector/Sector';
import NewTour from './NewTour/NewTour';
import Tour from './Tour/Tour';
import styles from './Onboarding.module.scss';

const cx = classNames.bind(styles);

const Onboarding = () => {
  const location = useLocation();

  const { isLoading, data } = useQueryMe();

  const [activeStep, setActiveStep] = useState(0);

  const [isNewOnboarding, setIsNewOnboarding] = useState(false);

  const isOnboarded = isLoading ? true : data.me.tags.includes('onboarded');

  const isInTeamsConfigure = location.pathname === '/ms-teams/configure';

  const isTourDone =
    isLoading ||
    (isNewOnboarding
      ? data.me.tags.includes('tour:1')
      : data.me.tags.includes('tour:3'));

  useEffect(() => {
    setIsNewOnboarding(data.me.flags?.includes('new-onboarding'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function updateLanguage() {
      if (!isOnboarded) {
        try {
          const browserLang = navigator.language || navigator.userLanguage;
          const lang = browserLang.split('-')[0];
          const languages = ['fr', 'nl', 'it', 'en'];
          const userLang = languages.includes(lang) ? lang : 'en';
          await mutationAppLanguageUpdate(userLang);
          trackEvent('onboarding', 'language');
        } catch (err) {
          reportError('onboarding', 'language', err);
        }
      }
    }

    updateLanguage();
  }, [isOnboarded]);

  const handleStepNext = () => {
    setActiveStep((tab) => tab + 1);
  };

  const handleStepPrevious = () => {
    setActiveStep((tab) => tab - 1);
  };

  return (
    <SubPage
      className={cx('sub-page')}
      isFullScreen
      isHeaderHidden
      isOnboarding
      isOpen={!isTourDone && !isInTeamsConfigure}
    >
      {isOnboarded ? (
        isNewOnboarding ? (
          <NewTour me={data.me} />
        ) : (
          <Tour me={data.me} />
        )
      ) : activeStep === 0 ? (
        <Sector handleStepNext={handleStepNext} />
      ) : isNewOnboarding ? (
        activeStep === 1 ? (
          <Institution
            handleStepNext={handleStepNext}
            me={data.me}
            onBack={handleStepPrevious}
          />
        ) : activeStep === 2 ? (
          <Role
            handleStepNext={handleStepNext}
            me={data.me}
            onBack={handleStepPrevious}
          />
        ) : activeStep === 3 ? (
          <Profile
            handleStepNext={handleStepNext}
            me={data.me}
            onBack={handleStepPrevious}
          />
        ) : activeStep === 4 ? (
          <Marketing me={data.me} onBack={handleStepPrevious} />
        ) : null
      ) : activeStep === 1 ? (
        <Role
          handleStepNext={handleStepNext}
          me={data.me}
          onBack={handleStepPrevious}
        />
      ) : activeStep === 2 ? (
        <PersonalInfo me={data.me} onBack={handleStepPrevious} />
      ) : null}
    </SubPage>
  );
};

export default Onboarding;
