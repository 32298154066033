import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import tutorialIllustrationsChecker from 'helpers/tutorialIllustrationsChecker';
import TutorialIllustration1 from 'images/tutorials/illustrationTutorial1.svg';
import TutorialIllustration2 from 'images/tutorials/illustrationTutorial2.svg';
import Context from 'lib/context';
import Button from 'uikit/Button';
import SubPage from 'uikit/SubPage/SubPage';

import imageStudy from './images/study.svg';
import styles from './CourseStudyModal.module.scss';

const cx = classNames.bind(styles);

const CourseStudyModal = ({
  course = null,
  handleCourseStudy,
  handleModalClose,
  isModalOpen,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/CourseStudy/CourseStudyModal',
  });

  const { theme } = useContext(Context);

  const { needTutorialIllustration1, needTutorialIllustration2 } =
    tutorialIllustrationsChecker(course?.id);

  return (
    <SubPage isFullScreen isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('container', `theme-${theme}`)}>
        <div className={cx('header')}>
          <div className={cx('image-container')}>
            {needTutorialIllustration1 || needTutorialIllustration2 ? (
              <img
                alt=""
                className={cx('image')}
                src={
                  needTutorialIllustration1
                    ? TutorialIllustration1
                    : TutorialIllustration2
                }
              />
            ) : (
              <>
                <div className={cx('image-background')} />
                <img alt="" className={cx('image')} src={imageStudy} />
                <div className={cx('image-line')} />
              </>
            )}
          </div>
          <div className={cx('text')}>
            <h1 className={cx('title-container')}>
              <span className={cx('welcome-in')}>
                {t('welcome-in').toUpperCase()}
              </span>
              <span className={cx('title')}>{course?.title}</span>
            </h1>
            <Button
              className={cx('action')}
              intent="primary"
              onClick={() => handleCourseStudy(null)}
            >
              {t('confirm-study-course')}
            </Button>
          </div>
        </div>
        {course?.chapters.length > 0 && (
          <h2 className={cx('study-by-chapter')}>{t('study-by-chapter')}</h2>
        )}
        {course?.chapters.map((chapter) => (
          <div className={cx('chapter-card')} key={chapter.id}>
            <div className={cx('chapter-title')}>{chapter.title}</div>
            <Button
              aria-label={t('aria-study-chapter', { title: chapter.title })}
              icon="user-graduate"
              intent="gray"
              onClick={() => handleCourseStudy(chapter.id)}
            >
              {t('study')}
            </Button>
          </div>
        ))}
      </div>
    </SubPage>
  );
};

CourseStudyModal.propTypes = {
  course: PropTypes.object,
  handleCourseStudy: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default CourseStudyModal;
