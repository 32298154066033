import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import PERMISSIONS from 'constants/permissions';
import extract from 'helpers/extract';
import {
  useMutationCommentDelete,
  useMutationCommentSolve,
  useMutationCommentUpdate,
  useMutationCommentRead,
} from 'gql/mutations/comment';
import { useMutationCommentReplyCreate } from 'gql/mutations/commentReply';
import { mutationEditorQuestionCommentsChange } from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import CommentContent from '../../CommentContent/CommentContent';
import CommentHeader from '../../CommentHeader/CommentHeader';
import CommentReply from './CommentReply/CommentReply';
import styles from './Comment.module.scss';

const cx = classNames.bind(styles);

const Comment = ({ className = null, comment, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Shared/Comment/Comments/Comment',
  });

  const mutationCommentReplyCreate = useMutationCommentReplyCreate();

  const mutationCommentUpdate = useMutationCommentUpdate();

  const mutationCommentDelete = useMutationCommentDelete();

  const mutationCommentRead = useMutationCommentRead();

  const mutationCommentSolve = useMutationCommentSolve();

  const {
    data: {
      editor: { commentSelected },
    },
  } = useQueryLocalEditor();

  const [isEditing, setIsEditing] = useState(false);

  const validationSchema = yup.object().shape({
    content: yup.string().trim().required(t('content-error-required')),
  });

  async function handleCommentReply(values, { resetForm }) {
    try {
      const variables = extract(values, validationSchema);
      await mutationCommentReplyCreate({
        ...variables,
        commentId: comment.id,
      });
      trackEvent('commentReply', 'create');
      resetForm();
    } catch (err) {
      reportError('commentReply', 'create', err);
    }
  }

  async function handleCommentSolve() {
    try {
      await mutationCommentSolve(comment);
      mutationEditorQuestionCommentsChange(null);
      trackEvent('comment', 'solve');
    } catch (err) {
      reportError('comment', 'solve', err);
    }
  }

  async function handleDelete() {
    try {
      await mutationCommentDelete(comment);
      trackEvent('comment', 'delete');
    } catch (err) {
      reportError('comment', 'delete', err);
    }
  }

  async function handleEdit(values) {
    try {
      const variables = extract(values, validationSchema);
      await mutationCommentUpdate(comment, variables);
      trackEvent('comment', 'update');
    } catch (err) {
      reportError('comment', 'update', err);
    } finally {
      setIsEditing(false);
    }
  }

  function handleEditToggle() {
    setIsEditing((s) => !s);
  }

  function handleThreadOpen() {
    if (!comment.isRead) {
      mutationCommentRead(comment);
    }
    mutationEditorQuestionCommentsChange(comment);
  }

  const isThreadOpen = commentSelected?.id === comment.id;

  const isVisible = !commentSelected || isThreadOpen;

  const canSolve = course.level >= PERMISSIONS.COLLABORATOR;

  return (
    isVisible && (
      <div className={cx('container', className)}>
        <div className={cx('header')}>
          <CommentHeader
            account={comment.account}
            className={cx('header-primary')}
            comment={comment}
            handleEdit={handleEditToggle}
            handleDelete={handleDelete}
          />
          {canSolve && (
            <Button
              icon="check"
              className={cx('solve-button')}
              onClick={handleCommentSolve}
              size="small"
              tooltip={t('solve')}
            />
          )}
        </div>
        <CommentContent
          className={cx('content')}
          comment={null}
          content={comment.content}
          handleCancel={handleEditToggle}
          handleSave={handleEdit}
          isEditing={isEditing}
          validationSchema={validationSchema}
        />
        {isThreadOpen ? (
          <>
            {comment.commentReplies.map((commentReply) => (
              <CommentReply
                className={cx('comment-reply')}
                commentReply={commentReply}
                key={commentReply.id}
              />
            ))}
            <div className={cx('line')} />
            <p className={cx('reply')}>{t('reply')}</p>
            <CommentContent
              comment={comment}
              content=""
              handleCancel={() => mutationEditorQuestionCommentsChange(null)}
              handleSave={handleCommentReply}
              isAnswering={true}
              isEditing={true}
              validationSchema={validationSchema}
            />
          </>
        ) : (
          <button
            className={cx('toggle-replies')}
            onClick={handleThreadOpen}
            type="button"
          >
            {t('see-thread', comment.commentReplies.length)}
            <Icon name="chevron-right" className={cx('chevron-right')} />
          </button>
        )}
      </div>
    )
  );
};

Comment.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
};

export default Comment;
