import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'uikit/Icon';
import HelpTooltip from 'uikit/HelpTooltip';

import styles from './ChapterProgress.module.scss';

const cx = classNames.bind(styles);

const ChapterProgress = ({
  className = null,
  chapter: chapterWithoutStats,
  course,
  isStudent = false,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/Progress/Chapters/ChapterProgress',
  });

  function getChapterWithStats() {
    const { questions } = chapterWithoutStats;
    const mastery =
      Math.round(
        (questions.reduce((m, q) => m + q.studyStats.mastery, 0) /
          (course.masteryThreshold * questions.length)) *
          100,
      ) || 0;
    const progression =
      Math.round(
        (questions.filter((q) => q.studyStats.attempted).length /
          questions.length) *
          100,
      ) || 0;
    const success =
      Math.round(
        (questions.filter((q) => q.studyStats.succeeded).length /
          questions.filter((q) => q.studyStats.attempted).length) *
          100,
      ) || 0;
    return { ...chapterWithoutStats, mastery, progression, success };
  }

  const chapter = isStudent ? getChapterWithStats() : chapterWithoutStats;

  return (
    <div className={cx('container', className)}>
      <h2 className={cx('title')}>{chapter.title}</h2>
      <div
        aria-label={t('aria-success', {
          success: chapter.success,
        })}
        className={cx('line')}
      >
        <Icon className={cx('indicator', 'success')} name="trophy" />
        <p className={cx('name')}>
          {t('success-name')}
          <HelpTooltip
            className={cx('tooltip')}
            tooltip={t('success-tooltip')}
          />
        </p>
        <div className={cx('gauge', 'success')}>
          <div
            style={{
              width: `${chapter.success}%`,
            }}
          />
        </div>
        <p className={cx('percentage')}>{`${chapter.success}%`}</p>
      </div>
      <div
        aria-label={t('aria-progression', {
          progression: chapter.progression,
        })}
        className={cx('line')}
      >
        <Icon className={cx('indicator', 'progression')} name="rocket" />
        <p className={cx('name')}>
          {t('progression-name')}
          <HelpTooltip
            className={cx('tooltip')}
            tooltip={t('progression-tooltip')}
          />
        </p>
        <div className={cx('gauge', 'progression')}>
          <div
            style={{
              width: `${chapter.progression}%`,
            }}
          />
        </div>
        <p className={cx('percentage')}>{`${chapter.progression}%`}</p>
      </div>
      <div
        aria-label={t('aria-mastery', {
          mastery: chapter.mastery,
        })}
        className={cx('line')}
      >
        <Icon className={cx('indicator', 'mastery')} name="dumbbell" />
        <p className={cx('name')}>
          {t('mastery-name')}
          <HelpTooltip
            className={cx('tooltip')}
            tooltip={t('mastery-tooltip')}
          />
        </p>
        <div className={cx('gauge', 'mastery')}>
          <div
            style={{
              width: `${chapter.mastery}%`,
            }}
          />
        </div>
        <p className={cx('percentage')}>{`${chapter.mastery}%`}</p>
      </div>
    </div>
  );
};

ChapterProgress.propTypes = {
  className: PropTypes.string,
  chapter: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  isStudent: PropTypes.bool,
};

export default ChapterProgress;
