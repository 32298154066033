import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import PERMISSIONS from 'constants/permissions';
import { useMutationCourseInvite } from 'gql/mutations/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputText from 'uikit/InputText';
import { notify } from 'uikit/Notifications';

import UnInvitedWarning from '../UnInvitedWarning/UnInvitedWarning';
import styles from './Email.module.scss';

const cx = classNames.bind(styles);

const Email = ({ className = null, course, group = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/Email',
  });

  const mutationCourseInvite = useMutationCourseInvite();

  const [unInvited, setUnInvited] = useState([]);

  async function handleStudentsInvite(values, form) {
    try {
      const usernames = extract(
        values.usernames,
        yup.string().trim().lowercase().required(),
      );
      const {
        data: {
          courseInvite: { notSentInvitations },
        },
      } = await mutationCourseInvite({
        courseId: course.id,
        groupId: group ? group.id : null,
        level: PERMISSIONS.STUDENT,
        usernames,
      });
      setUnInvited(notSentInvitations);
      if (notSentInvitations.length === 0) {
        notify('success', t('students-invite-success'));
      }
      trackEvent('students', 'invite');
    } catch (err) {
      reportError('students', 'invite', err);
    } finally {
      form.setFieldValue('usernames', '');
    }
  }

  return (
    <div className={cx(className)}>
      <div className={cx('container')}>
        <div className={cx('icon')}>
          <Icon name="envelope" />
        </div>
        <div className={cx('grow')}>
          <Formik
            initialValues={{
              usernames: '',
            }}
            onSubmit={handleStudentsInvite}
          >
            {({ isSubmitting }) => (
              <Form className={cx('container')}>
                <InputText
                  className={cx('field')}
                  id="input-email"
                  label={t('email')}
                  name="usernames"
                  placeholder={t('emails-placeholder')}
                  type="textarea"
                />
                <Button
                  aria-label={t('aria-invite')}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {t('invite')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <UnInvitedWarning unInvited={unInvited} />
    </div>
  );
};

Email.propTypes = {
  classNames: PropTypes.string,
  course: PropTypes.object.isRequired,
  group: PropTypes.object,
};

export default Email;
