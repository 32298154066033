import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import StudentCard from '../StudentCard/StudentCard';
import styles from './Students.module.scss';

const cx = classNames.bind(styles);

const Students = ({ course, groupId = null }) => {
  const students =
    (groupId === null
      ? course.students
      : course.groups.find((c) => c.id === groupId)?.students) || [];

  return (
    <div>
      {students.map((student, index) => (
        <StudentCard
          className={cx('student')}
          course={course}
          index={index}
          key={student.id}
          student={student}
        />
      ))}
    </div>
  );
};

Students.propTypes = {
  course: PropTypes.object.isRequired,
  groupId: PropTypes.string,
};

export default Students;
