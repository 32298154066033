import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import CourseDelete from 'components/Shared/CourseDelete';
import CourseLeave from 'components/Shared/CourseLeave';
import PERMISSIONS from 'constants/permissions';
import { useMutationCoursePin } from 'gql/mutations/course';
import useSiteContext from 'hooks/useSiteContext';
import Button from 'uikit/Button';
import Menu from 'uikit/Menu';
import Icon from 'uikit/Icon';
import Tooltip from 'uikit/Tooltip';

import styles from './CourseItem.module.scss';

const cx = classNames.bind(styles);

function getStyle(style, snapshot) {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    transitionDuration: `0.001s`,
  };
}

const CourseItem = ({ className = null, course, folderId, index }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard/CourseItem',
  });

  const { isEditionDisabled } = useSiteContext();

  const mutationCoursePin = useMutationCoursePin();

  async function handleCoursePin(evt) {
    try {
      evt.preventDefault();
      evt.stopPropagation();
      await mutationCoursePin(course);
    } catch (err) {
      // empty
    }
  }

  return (
    <Draggable draggableId={course.id} index={index}>
      {(providedCourse, snapshotCourse) => (
        <div
          {...providedCourse.draggableProps}
          className={cx(
            'item-container',
            `theme-${course.themeBackground}`,
            {
              shadow: !!folderId,
              isDragging: snapshotCourse.isDragging,
            },
            className,
          )}
          ref={providedCourse.innerRef}
          style={getStyle(providedCourse.draggableProps.style, snapshotCourse)}
        >
          <Link
            aria-label={course.title}
            className={cx('link')}
            to={`/course/${course.id}/`}
          >
            <div
              {...providedCourse.dragHandleProps}
              className={cx('sort')}
              tabIndex={-1}
            >
              <Icon name="bars" />
            </div>
            <Tooltip
              tooltip={
                course.isPinned ? t('remove-favorite') : t('add-favorite')
              }
            >
              <button
                aria-label={
                  course.isPinned
                    ? t('aria-remove-favorite', {
                        name: course.title,
                      })
                    : t('aria-add-favorite', {
                        name: course.title,
                      })
                }
                aria-checked={course.isPinned}
                className={cx('pin')}
                onClick={handleCoursePin}
                role="checkbox"
                type="button"
              >
                <Icon
                  name="star"
                  variant={course.isPinned ? 'solid' : 'regular'}
                />
              </button>
            </Tooltip>
            <div className={cx('icon')}>
              <Icon name={snapshotCourse.isDragging ? 'sort' : 'book'} />
            </div>
            <h2 className={cx('title')}>{course.title}</h2>
            <p className={cx('owner')}>
              {`${course.owner.firstName} ${course.owner.lastName}`}
            </p>
            {course.level >= PERMISSIONS.COLLABORATOR && (
              <p className={cx('participants')}>
                <Icon
                  className={cx('participants-icon')}
                  name="chalkboard-user"
                />
              </p>
            )}
            {!isEditionDisabled && (
              <Menu
                className={cx('menu')}
                placement="bottom-end"
                toggle={
                  <Button
                    aria-label={t('aria-options', {
                      name: course.title,
                    })}
                    icon="ellipsis-v"
                  />
                }
              >
                {course.level >= PERMISSIONS.OWNER ? (
                  <CourseDelete course={course} />
                ) : (
                  <CourseLeave course={course} />
                )}
              </Menu>
            )}
          </Link>
        </div>
      )}
    </Draggable>
  );
};

CourseItem.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  folderId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default CourseItem;
