import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  mutationEditorFolderDelete,
  mutationEditorFolderUpdate,
} from 'gql/mutations/editor';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import CourseItem from '../CourseItem/CourseItem';
import styles from './FolderCard.module.scss';

const cx = classNames.bind(styles);

const FolderCard = ({ courses, folder }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard/Finder/FolderCard',
  });

  const { state } = useLocation();

  const filteredCourses =
    courses.filter((course) => course.folderId === folder.id) || [];

  const [isExpanded, setExpanded] = useState(() => {
    const filteredCoursesIds = filteredCourses.map((course) => course.id);
    return filteredCoursesIds.includes(state?.currentDisplayedCourseId);
  });

  function handleFolderDelete(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    mutationEditorFolderDelete(folder);
  }

  function handleFolderExpandedToggle(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    setExpanded((s) => !s);
  }

  function handleFolderUpdate(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    mutationEditorFolderUpdate(folder);
  }

  return (
    <Droppable droppableId={folder.id} type="course">
      {(providedCourses, snapshotCourses) => (
        <div
          className={cx('container', {
            isDraggingOver: snapshotCourses.isDraggingOver,
          })}
          ref={providedCourses.innerRef}
          {...providedCourses.droppableProps}
        >
          <button
            aria-label={t(`aria-${isExpanded ? 'close' : 'open'}-folder`, {
              name: folder.title,
            })}
            className={cx('folder', {
              'margin-bottom': isExpanded,
            })}
            onClick={handleFolderExpandedToggle}
            type="button"
          >
            <div className={cx('toggle')}>
              <Icon name={isExpanded ? 'chevron-down' : 'chevron-right'} />
            </div>
            <div className={cx('folder-icon')}>
              <Icon name="folder" />
            </div>
            <p className={cx('title')}>{folder.title}</p>
            <p className={cx('course-number')}>{filteredCourses.length}</p>
            <div className={cx('fill')} />
            <Menu
              placement="bottom-end"
              toggle={
                <Button
                  aria-label={t('aria-options', {
                    name: folder.title,
                  })}
                  className={cx('action')}
                  icon="ellipsis-v"
                  size="small"
                  tooltip={t('options')}
                />
              }
            >
              <MenuItem icon="edit" onClick={handleFolderUpdate}>
                {t('rename-folder')}
              </MenuItem>
              <MenuItem
                icon="trash-alt"
                onClick={handleFolderDelete}
                variant="regular"
              >
                {t('delete-folder')}
              </MenuItem>
            </Menu>
          </button>
          {isExpanded && (
            <div
              className={cx('courses', {
                isDraggingOver: snapshotCourses.isDraggingOver,
              })}
            >
              {filteredCourses.map((course, index) => (
                <CourseItem
                  className={cx('course')}
                  course={course}
                  folderId={folder.id}
                  index={index}
                  key={course.id}
                />
              ))}
              {providedCourses.placeholder}
            </div>
          )}
        </div>
      )}
    </Droppable>
  );
};

FolderCard.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  folder: PropTypes.object.isRequired,
};

export default FolderCard;
