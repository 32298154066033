import classNames from 'classnames/bind';
import React from 'react';

import useSiteContext from 'hooks/useSiteContext';

import Collaborators from './Collaborators/Collaborators';
import Students from './Students/Students';
import styles from './Participants.module.scss';

const cx = classNames.bind(styles);

const Participants = () => {
  const { isGAR, isTNECanope, isRoleTeacher } = useSiteContext();

  return (
    <div>
      {!isTNECanope && <Collaborators className={cx('collaborators')} />}
      {(!isGAR || (isGAR && isRoleTeacher)) && <Students />}
    </div>
  );
};

export default Participants;
