import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import extract from 'helpers/extract';
import {
  useMutationCommentReplyUpdate,
  useMutationCommentReplyDelete,
} from 'gql/mutations/commentReply';
import { trackEvent, reportError } from 'lib/tracking';

import CommentContent from '../../../CommentContent/CommentContent';
import CommentHeader from '../../../CommentHeader/CommentHeader';
import styles from './CommentReply.module.scss';

const cx = classNames.bind(styles);

const CommentReply = ({ commentReply }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Shared/Comment/Comments/Comment/CommentReply',
  });

  const mutationCommentReplyUpdate = useMutationCommentReplyUpdate();

  const mutationCommentReplyDelete = useMutationCommentReplyDelete();

  const [isEditing, setIsEditing] = useState(false);

  const validationSchema = yup.object().shape({
    content: yup.string().trim().required(t('content-error-required')),
  });

  async function handleDelete() {
    try {
      await mutationCommentReplyDelete(commentReply);
      trackEvent('commentReply', 'delete');
    } catch (err) {
      reportError('commentReply', 'delete', err);
    }
  }

  async function handleEdit(values) {
    try {
      const variables = extract(values, validationSchema);
      await mutationCommentReplyUpdate(commentReply, variables);
      trackEvent('commentReply', 'update');
    } catch (err) {
      reportError('commentReply', 'update', err);
    } finally {
      setIsEditing(false);
    }
  }

  function handleEditToggle() {
    setIsEditing((s) => !s);
  }

  return (
    <div>
      <CommentHeader
        account={commentReply.account}
        className={cx('header')}
        comment={commentReply}
        handleEdit={handleEditToggle}
        handleDelete={handleDelete}
      />
      <CommentContent
        comment={null}
        content={commentReply.content}
        handleCancel={handleEditToggle}
        handleSave={handleEdit}
        isEditing={isEditing}
        validationSchema={validationSchema}
      />
    </div>
  );
};

CommentReply.propTypes = {
  commentReply: PropTypes.object.isRequired,
};

export default CommentReply;
