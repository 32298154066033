import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { mutationEditorStudentDelete } from 'gql/mutations/editor';
import Button from 'uikit/Button';

import styles from './StudentCard.module.scss';

const cx = classNames.bind(styles);

const StudentCard = ({ className = null, course, index, student }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students/Finder/StudentCard',
  });

  function handleStudentDelete() {
    mutationEditorStudentDelete(student);
  }

  function getStyle(style, snapshot) {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      transitionDuration: `0.001s`,
    };
  }

  return (
    <Draggable draggableId={student.id} index={index + 1}>
      {(providedStudent, snapshotStudent) => (
        <div
          className={cx(
            'container',
            {
              isDragging: snapshotStudent.isDragging,
            },
            className,
          )}
          ref={providedStudent.innerRef}
          role="button"
          {...providedStudent.draggableProps}
          {...providedStudent.dragHandleProps}
          style={getStyle(
            providedStudent.draggableProps.style,
            snapshotStudent,
          )}
        >
          <div
            aria-hidden="true"
            className={cx('logo')}
            style={{
              backgroundImage: `url("${student.picture}")`,
            }}
          >
            {!student.picture &&
              `${student.lastName && student.lastName[0]}${
                student.firstName && student.firstName[0]
              }`.toUpperCase()}
            <div
              className={cx('status', {
                isActive: student.isActive,
              })}
            />
          </div>
          <div className={cx('info')}>
            <h3 className={cx('title')}>
              {`${student.lastName.toUpperCase()}, ${student.firstName}`}
            </h3>
            <p className={cx('email')}>{`${
              student.email || student.username
            }`}</p>
          </div>
          {!course.isExam && (
            <Button
              as={Link}
              className={cx('action')}
              icon="chart-bar"
              size="small"
              to={{
                pathname: `/course/${course.id}/statistics`,
                state: {
                  type: 'student',
                  studentId: student.id,
                },
              }}
              tooltip={t('statistics')}
            />
          )}
          <Button
            className={cx('action')}
            icon="trash-alt"
            onClick={handleStudentDelete}
            size="small"
            tooltip={t('delete-student')}
            variant="regular"
          />
        </div>
      )}
    </Draggable>
  );
};

StudentCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  student: PropTypes.object.isRequired,
};

export default StudentCard;
