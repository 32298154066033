import classNames from 'classnames/bind';
import * as dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import LoadingScreen from 'components/Shared/LoadingScreen';
import MenuContainer from 'components/Shared/MenuContainer';
import PERMISSIONS from 'constants/permissions';
import { mutationEditorReset } from 'gql/mutations/editor';
import { useMutationCourseOpen } from 'gql/mutations/course';
import { useQueryFullCourse } from 'gql/queries/course';
import useOnError from 'hooks/useOnError';
import useSiteContext from 'hooks/useSiteContext';
import Context from 'lib/context';
import history from 'lib/history';
import { trackEvent } from 'lib/tracking';

import BackButton from './Shared/BackButton/BackButton';
import Student from './Student/Student';
import Teacher from './Teacher/Teacher';
import styles from './Course.module.scss';

const cx = classNames.bind(styles);

const Course = () => {
  const { courseId } = useParams();

  const { isInTeams } = useSiteContext();

  const mutationCourseOpen = useMutationCourseOpen();

  const { error, isLoading, data } = useQueryFullCourse({
    variables: {
      courseId,
    },
  });

  useOnError(error, () => {
    history.push('/');
  });

  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);

  useEffect(() => {
    async function trackLastConnection() {
      try {
        if (
          data &&
          (dayjs(data.course.lastConnection)
            .add(5, 'minutes')
            .isBefore(new Date()) ||
            !data.course.lastConnection)
        ) {
          await mutationCourseOpen(data.course);
          trackEvent('course', 'open');
        }
      } catch {
        //
      }
    }
    trackLastConnection();
  }, [data, mutationCourseOpen]);

  const course = data?.course;

  return (
    <Context.Provider
      value={{
        theme: data?.course.themeBackground,
      }}
    >
      <MenuContainer
        headerAction={!isInTeams && <BackButton course={course} />}
      >
        <div className={cx('header')}>
          {!isInTeams && <BackButton course={course} />}
        </div>
        {isLoading ? (
          <LoadingScreen />
        ) : course.level >= PERMISSIONS.COLLABORATOR ? (
          <Teacher course={course} />
        ) : (
          <Student course={course} />
        )}
      </MenuContainer>
    </Context.Provider>
  );
};

export default Course;
