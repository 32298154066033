import classNames from 'classnames/bind';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import { useMutationCommentReplyGenerate } from 'gql/mutations/commentReply';
import Button from 'uikit/Button';
import { notify } from 'uikit/Notifications';
import Tooltip from 'uikit/Tooltip';

import styles from './CommentContent.module.scss';

const cx = classNames.bind(styles);

const CommentContent = ({
  className = null,
  content = null,
  comment = null,
  handleCancel = null,
  handleSave,
  isAnswering = false,
  isEditing = false,
  validationSchema,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Shared/Comment/CommentContent',
  });

  const {
    data: {
      editor: { questionEditing },
    },
  } = useQueryLocalEditor();

  const { data: meData } = useQueryMe();

  const mutationCommentReplyGenerate = useMutationCommentReplyGenerate();

  const [error, setError] = useState(false);

  const [isLoading, setLoading] = useState(false);

  async function handleCommentReplyGenerate(setFieldValue) {
    if (comment) {
      try {
        setError(false);
        setLoading(true);
        const {
          data: { commentReplyGenerate: commentReply },
        } = await mutationCommentReplyGenerate({ commentId: comment.id });
        setFieldValue('content', commentReply);
        notify('ia', t('ai-notification'));
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }

  const hasAcceptedAI = meData?.me.wantsAI;

  const hasAIFeatureFlag = meData?.me.flags?.includes('ai-comment-reply');

  const isQuestionMCQ = questionEditing?.type === 'MCQ';

  const hasAIAccess = hasAcceptedAI && hasAIFeatureFlag && isQuestionMCQ;

  return (
    <div className={className}>
      {isEditing ? (
        <Formik
          enableReinitialize
          initialValues={{
            content: content,
          }}
          onSubmit={handleSave}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              {isAnswering && hasAIAccess && (
                <Tooltip tooltip={t('ai-tooltip')}>
                  <Button
                    className={cx('ai-button')}
                    icon="wand-sparkles"
                    intent="ia"
                    isLoading={isLoading}
                    onClick={() => handleCommentReplyGenerate(setFieldValue)}
                    size="small"
                  />
                </Tooltip>
              )}
              {error && (
                <p className={cx('error-message')}>{t('error-message')}</p>
              )}
              <Field
                aria-label={t('aria-content')}
                autoFocus
                placeholder={t('content-placeholder')}
                className={cx('field')}
                name="content"
                as="textarea"
              />
              <div className={cx('actions')}>
                {handleCancel && (
                  <Button onClick={handleCancel} size="small">
                    {t('cancel')}
                  </Button>
                )}
                <Button
                  intent="primary"
                  size="small"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('send')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={cx('content')}>{content}</div>
      )}
    </div>
  );
};

CommentContent.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.object,
  content: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  handleCommentReplyGenerate: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  hasAIAccess: PropTypes.bool.isRequired,
  isAnswering: PropTypes.bool,
  isEditing: PropTypes.bool,
  validationSchema: PropTypes.object.isRequired,
};

export default CommentContent;
