import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CourseJoin from 'components/Shared/CourseJoin';
import { useMutationCourseCreate } from 'gql/mutations/course';
import { useQueryMe } from 'gql/queries/me';
import useModal from 'hooks/useModal';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';

import imageCelebration from './images/celebration.svg';
import styles from './Empty.module.scss';

const cx = classNames.bind(styles);

const Empty = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard/Empty',
  });

  const mutationCourseCreate = useMutationCourseCreate();

  const {
    isModalOpen: isCourseJoinDisplayed,
    handleModalOpen: handleCourseJoinOpen,
    handleModalClose: handleCourseJoinClose,
  } = useModal();

  const { isLoading, data } = useQueryMe();

  const [isCourseCreating, setCourseCreating] = useState(false);

  async function handleCourseCreate() {
    try {
      setCourseCreating(true);
      const {
        data: { courseCreate },
      } = await mutationCourseCreate({
        title: t('new-course-title'),
      });
      history.push(`/course/${courseCreate.id}/`);
      trackEvent('course', 'create');
    } catch (err) {
      reportError('course', 'create', err);
    } finally {
      setCourseCreating(false);
    }
  }

  const role = isLoading ? 'student' : data.me.role || 'student';

  return (
    <>
      <div className={cx('container')}>
        <div className={cx('section')}>
          <img
            alt=""
            aria-hidden="true"
            className={cx('image')}
            src={imageCelebration}
          />
          <h3 className={cx('title')}>{t('title')}</h3>
          <h3 className={cx('subtitle')}>{t('subtitle')}</h3>
          {role === 'teacher' ? (
            <>
              <Button
                className={cx('action')}
                intent="primary"
                isLoading={isCourseCreating}
                onClick={handleCourseCreate}
                size="large"
              >
                {t('create-course')}
              </Button>
              <Button
                className={cx('action')}
                onClick={handleCourseJoinOpen}
                size="large"
              >
                {t('join-course')}
              </Button>
            </>
          ) : (
            <>
              <Button
                className={cx('action')}
                intent="primary"
                onClick={handleCourseJoinOpen}
                size="large"
              >
                {t('join-course')}
              </Button>
              <Button
                className={cx('action')}
                isLoading={isCourseCreating}
                onClick={handleCourseCreate}
                size="large"
              >
                {t('create-course')}
              </Button>
            </>
          )}
        </div>
        <p className={cx('or')}>
          <span>{t('or')}</span>
        </p>
        <div className={cx('section')}>
          <h3 className={cx('title')}>{t('title2')}</h3>
          <h3 className={cx('subtitle')}>{t('subtitle2')}</h3>
          <Button
            as={Link}
            className={cx('action')}
            to="/public-courses"
            size="large"
          >
            {t('public-courses')}
          </Button>
        </div>
      </div>
      <CourseJoin
        isModalOpen={isCourseJoinDisplayed}
        handleModalClose={handleCourseJoinClose}
      />
    </>
  );
};

export default Empty;
