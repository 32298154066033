import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ME_DATA, ME_FULL_DATA } from 'gql/fragments';
import { QUERY_COURSES_ME } from 'gql/queries/course';

export const ME_ONBOARD = gql`
  mutation MeOnboard($referralCode: String, $referralDate: Date) {
    meOnboard(referralCode: $referralCode, referralDate: $referralDate) {
      ...MeData
    }
  }
  ${ME_DATA}
`;

export function useMutationMeOnboard() {
  const [mutation] = useMutation(ME_ONBOARD);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        refetchQueries: [
          {
            query: QUERY_COURSES_ME,
          },
        ],
      }),
    [mutation],
  );
}

export const ME_TAG = gql`
  mutation MeTag($tags: [String]!) {
    meTag(tags: $tags) {
      ...MeData
    }
  }
  ${ME_DATA}
`;

export function useMutationMeTag() {
  const [mutation] = useMutation(ME_TAG);
  return useCallback(
    (tags) =>
      mutation({
        variables: {
          tags,
        },
      }),
    [mutation],
  );
}

export const ME_UNTAG = gql`
  mutation MeUntag($tags: [String]!) {
    meUntag(tags: $tags) {
      ...MeData
    }
  }
  ${ME_DATA}
`;

export function useMutationMeUntag() {
  const [mutation] = useMutation(ME_UNTAG);
  return useCallback(
    (tags) =>
      mutation({
        variables: {
          tags,
        },
      }),
    [mutation],
  );
}

export const ME_UPDATE = gql`
  mutation MeUpdate(
    $wantsMarketing: Boolean
    $firstName: String
    $lastName: String
    $picture: String
    $language: String
    $sector: String
    $role: String
    $institution: String
    $branch: String
    $country: String
    $emailNotifications: JSON
    $pushNotifications: JSON
    $pushToken: String
    $job: String
    $biography: String
    $twitter: String
    $linkedin: String
    $metadata: JSON
    $wantsAI: Boolean
  ) {
    meUpdate(
      wantsMarketing: $wantsMarketing
      firstName: $firstName
      lastName: $lastName
      picture: $picture
      language: $language
      sector: $sector
      role: $role
      institution: $institution
      branch: $branch
      country: $country
      emailNotifications: $emailNotifications
      pushNotifications: $pushNotifications
      pushToken: $pushToken
      job: $job
      biography: $biography
      twitter: $twitter
      linkedin: $linkedin
      metadata: $metadata
      wantsAI: $wantsAI
    ) {
      ...MeData
    }
  }
  ${ME_DATA}
`;

export function useMutationMeUpdate() {
  const [mutation] = useMutation(ME_UPDATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const ME_COUPON = gql`
  mutation MeCoupon($accessCode: String!) {
    meCoupon(accessCode: $accessCode) {
      ...MeFullData
    }
  }
  ${ME_FULL_DATA}
`;

export function useMutationMeCoupon() {
  const [mutation] = useMutation(ME_COUPON);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const ME_DELETE = gql`
  mutation MeDelete {
    meDelete
  }
`;

export function useMutationMeDelete() {
  const [mutation] = useMutation(ME_DELETE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const ME_UPDATE_LANGUAGE = gql`
  mutation MeUpdateLanguage($language: String!) {
    meUpdateLanguage(language: $language)
  }
`;

