import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import CourseLeave from 'components/Shared/CourseLeave';
import LoadingScreen from 'components/Shared/LoadingScreen';
import CourseStudy from 'components/Course/Shared/CourseStudy/CourseStudy';
import ExamStart from 'components/Course/Shared/ExamStart/ExamStart';
import { mutationEditorQuestionPropose } from 'gql/mutations/editor';
import { useQueryCourseGames } from 'gql/queries/course';
import { useQueryTestStatus } from 'gql/queries/test';
import tutorialIllustrationsChecker from 'helpers/tutorialIllustrationsChecker';
import useHotkeys from 'hooks/useHotkeys';
import useSiteContext from 'hooks/useSiteContext';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu from 'uikit/Menu';

import BACKGROUND_IMAGE from './images/background.svg';
import TUTORIAL1_IMAGE from './images/background_tutorial1.png';
import TUTORIAL2_IMAGE from './images/background_tutorial2.png';
import MOODLE_IMAGE from './images/moodle.png';
import GamePlay from './GamePlay/GamePlay';
import styles from './Cover.module.scss';

const cx = classNames.bind(styles);

const Cover = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Student/Cover',
  });

  const { isEditionDisabled } = useSiteContext();

  const { courseId } = useParams();

  const { isLoading: isLoadingCourse, data: dataCourse } = useQueryCourseGames({
    variables: {
      courseId,
    },
  });

  const { isLoading: isLoadingTest, data: dataTest } = useQueryTestStatus({
    variables: {
      courseId,
    },
    skip: isLoadingCourse || !dataCourse.course.isExam,
  });

  useHotkeys('shift+n', mutationEditorQuestionPropose);

  const { needTutorialIllustration1, needTutorialIllustration2 } =
    tutorialIllustrationsChecker(courseId);

  const isLoading =
    isLoadingCourse || (dataCourse.course.isExam && isLoadingTest);

  const canTakeExam =
    dataCourse?.course.isExam &&
    [201, 202, 203].includes(dataTest?.testStatus.status);

  const canSeeExamReview =
    dataCourse?.course.isExam &&
    dataCourse?.course.isExamReviewEnabled &&
    (dataTest?.testStatus.status === 203 ||
      dataTest?.testStatus.when === 'after');

  const course = dataCourse?.course;

  const canProposeQuestion =
    course?.isStudentCollaborationEnabled && !course?.isExam;

  const backgroundURL = needTutorialIllustration1
    ? TUTORIAL1_IMAGE
    : needTutorialIllustration2
    ? TUTORIAL2_IMAGE
    : BACKGROUND_IMAGE;

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div
      className={cx('container', `theme-${course.themeBackground}`, className)}
      style={{
        backgroundImage: `url(${backgroundURL})`,
      }}
    >
      <h1
        className={cx('title', {
          'title-with-introduction': !!course.introduction,
        })}
      >
        {course.title}
      </h1>
      {course.introduction && (
        <p className={cx('introduction')}>{course.introduction}</p>
      )}
      <div className={cx('actions')}>
        {course.isExam ? (
          <>
            {canTakeExam && (
              <ExamStart className={cx('action')} course={course} />
            )}
            {canSeeExamReview && (
              <Link
                className={cx('action', 'review')}
                to={`/test-review/${course.id}`}
              >
                {t('test-review')}
                <Icon className={cx('chevron')} name="chevron-right" />
              </Link>
            )}
          </>
        ) : (
          <>
            <CourseStudy className={cx('action')} course={course} />
            {course.games.length > 0 && (
              <GamePlay className={cx('action')} course={course} />
            )}
          </>
        )}
        {course.moodle.isMoodle && (
          <Button
            as="a"
            className={cx('action', 'moodle-button')}
            href={course.moodle.courseUrl}
            icon={
              <img
                alt=""
                aria-hidden="true"
                className={cx('moodle-image')}
                src={MOODLE_IMAGE}
              />
            }
            intent="white"
            size="large"
            target="_top"
          >
            {t('moodle')}
          </Button>
        )}
      </div>
      {!isEditionDisabled && (
        <Menu
          className={cx('menu')}
          placement="bottom-end"
          toggle={
            <Button
              icon="sign-out"
              intent="white"
              size="small"
              tooltip={t('leave')}
            />
          }
        >
          <CourseLeave course={course} />
        </Menu>
      )}
      {canProposeQuestion && (
        <Button
          className={cx('propose-question')}
          icon="plus"
          intent="white"
          onClick={mutationEditorQuestionPropose}
        >
          {t('suggest-question')}
        </Button>
      )}
    </div>
  );
};

Cover.propTypes = {
  className: PropTypes.string,
};

export default Cover;
