import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseJoinByCode } from 'gql/mutations/course';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import styles from './CourseItem.module.scss';

const cx = classNames.bind(styles);

const CourseItem = ({ className, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard/CoursesGAR/CourseItem',
  });

  const mutationCourseJoinByCode = useMutationCourseJoinByCode();

  async function handleCourseJoin() {
    try {
      const {
        data: { courseJoinByCode },
      } = await mutationCourseJoinByCode({
        accessCode: course.accessCode,
        from: 9,
      });
      history.push(`/course/${courseJoinByCode.id}/`);
      trackEvent('course', 'join');
    } catch (err) {
      reportError('course', 'join', err);
    }
  }

  return (
    <div
      className={cx('container', `theme-${course.themeBackground}`, className)}
    >
      <div className={cx('icon')}>
        <Icon name="book" />
      </div>
      <h2 className={cx('title')}>{course.title}</h2>
      <Button intent="primary" onClick={handleCourseJoin}>
        {t('join')}
      </Button>
    </div>
  );
};

CourseItem.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default CourseItem;
