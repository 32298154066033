import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { useMutationStudentSort } from 'gql/mutations/student';
import { trackEvent, reportError } from 'lib/tracking';

import Groups from './Groups/Groups';
import Standalone from './Standalone/Standalone';

import styles from './Finder.module.scss';

const cx = classNames.bind(styles);

const Finder = ({ course }) => {
  const mutationStudentSort = useMutationStudentSort();

  async function handleSort({ draggableId, source, destination }) {
    try {
      if (
        !destination ||
        (source.index === destination.index &&
          source.droppableId === destination.droppableId)
      ) {
        return;
      }
      const container =
        source.droppableId === 'course'
          ? course
          : course.groups.find((c) => c.id === source.droppableId);
      const student = container?.students.find((q) => q.id === draggableId);
      if (student) {
        mutationStudentSort(student, {
          groupId:
            destination.droppableId === 'course'
              ? null
              : destination.droppableId,
        });
      }
      trackEvent('student', 'sort');
    } catch (err) {
      reportError('student', 'sort', err);
    }
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleSort}>
        <Groups course={course} />
        <Standalone className={cx('group')} course={course} />
      </DragDropContext>
    </div>
  );
};

Finder.propTypes = {
  course: PropTypes.object.isRequired,
};

export default Finder;
