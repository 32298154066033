import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { useQueryMe } from 'gql/queries/me';

import GroupCard from './GroupCard/GroupCard';
import styles from './FinderGAR.module.scss';

const cx = classNames.bind(styles);

const FinderGAR = ({ course }) => {
  const { isLoading } = useQueryMe();

  const divs = (course.gar?.div || []).map((div) => {
    const [key, name] = div.split('##');
    return {
      title: `${name} (${key})`,
      students: course.students.filter((student) =>
        (student.metadata.garDIV || []).includes(div),
      ),
    };
  });

  const gros = (course.gar?.gro || []).map((gro) => {
    const [key, name] = gro.split('##');
    return {
      title: `${name} (${key})`,
      students: course.students.filter((student) =>
        (student.metadata.garGRO || []).includes(gro),
      ),
    };
  });

  const groups = isLoading ? [] : [...divs, ...gros];

  return (
    <div>
      {groups.map((group) => (
        <GroupCard className={cx('group')} course={course} group={group} />
      ))}
    </div>
  );
};

FinderGAR.propTypes = {
  course: PropTypes.object.isRequired,
};

export default FinderGAR;
