import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryCoursesGAR } from 'gql/queries/course';

import CourseItem from './CourseItem/CourseItem';
import styles from './CoursesGAR.module.scss';

const cx = classNames.bind(styles);

const CoursesGAR = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard/CoursesGAR',
  });

  const { isLoading, data } = useQueryCoursesGAR();

  const courses = data?.coursesGAR;

  return isLoading ? null : (
    <div className={cx('container')}>
      <h1 className={cx('title')}>{t('title')}</h1>
      {courses.length > 0 ? (
        courses.map((course) => (
          <CourseItem
            className={cx('course-item')}
            course={course}
            key={course.id}
          />
        ))
      ) : (
        <p className={cx('empty')}>{t('empty')}</p>
      )}
    </div>
  );
};

CoursesGAR.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default CoursesGAR;
