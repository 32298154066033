import classNames from 'classnames/bind';
import * as dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryCourse } from 'gql/queries/course';
import FormattedText from 'uikit/FormattedText';

import imageExam from './images/exam.svg';
import styles from './Exam.module.scss';

const cx = classNames.bind(styles);

const Exam = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Student/Exam',
  });

  const { courseId } = useParams();

  const { isLoading, data } = useQueryCourse({
    variables: {
      courseId,
    },
  });

  const course = data?.course;

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={cx('container')}>
      <img alt="" aria-hidden="true" className={cx('image')} src={imageExam} />
      <p className={cx('title')}>{t('start-time')}</p>
      <p>{dayjs(course.examStartTime).format('LLLL')}</p>
      <p className={cx('title')}>{t('stop-time')}</p>
      <p>{dayjs(course.examStopTime).format('LLLL')}</p>
      {course.examDurationMinutes > 0 && (
        <>
          <p className={cx('title')}>{t('duration')}</p>
          <p>{`${course.examDurationMinutes} minutes`}</p>
        </>
      )}
      {course.examInstructions && (
        <>
          <p className={cx('title')}>{t('instructions')}</p>
          <FormattedText>{course.examInstructions}</FormattedText>
        </>
      )}
    </div>
  );
};

export default Exam;
