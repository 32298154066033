import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { useMutationCourseSort } from 'gql/mutations/course';
import { trackEvent, reportError } from 'lib/tracking';

import FolderCard from './FolderCard/FolderCard';
import Standalone from './Standalone/Standalone';

const Finder = ({ courses, folders }) => {
  const mutationCourseSort = useMutationCourseSort();

  async function handleCourseSort({ draggableId, source, destination }) {
    try {
      if (
        !destination ||
        (source.index === destination.index &&
          source.droppableId === destination.droppableId)
      ) {
        return;
      }
      const course = courses.find((c) => c.id === draggableId);
      if (course) {
        mutationCourseSort(course, {
          folderId:
            destination.droppableId === 'dashboard'
              ? null
              : destination.droppableId,
        });
      }
      trackEvent('course', 'sort');
    } catch (err) {
      reportError('course', 'sort', err);
    }
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleCourseSort}>
        {folders.map((folder) => (
          <FolderCard courses={courses} folder={folder} key={folder.id} />
        ))}
        <Standalone courses={courses} />
      </DragDropContext>
    </div>
  );
};

Finder.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Finder;
