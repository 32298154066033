import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import HelpTooltip from 'uikit/HelpTooltip';

import styles from './UnInvitedWarning.module.scss';

const cx = classNames.bind(styles);

const UnInvitedWarning = ({ className = null, unInvited }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/UnInvitedWarning',
  });

  return (
    <>
      {unInvited.length > 0 && (
        <div className={cx(className)}>
          <div className={cx('warning-container')}>
            <div className={cx('warning-title-container')}>
              <p className={cx('warning-title')}>
                {t('uninvited', {
                  count: unInvited.length,
                })}
              </p>
              <HelpTooltip
                className={cx('warning-helper')}
                tooltip={t('uninvited-helper', {
                  count: unInvited.length,
                })}
              />
            </div>
            <ul>
              {unInvited.map((username) => (
                <li className={cx('warning-element')} key={username}>
                  {username}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

UnInvitedWarning.propTypes = {
  className: PropTypes.string,
  unInvited: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UnInvitedWarning;
