import * as dayjs from 'dayjs';
import * as DOMPurify from 'dompurify';
import storeJS from 'store';

import apollo from 'gql';
import { appVar } from 'gql/local';
import { ME_UPDATE_LANGUAGE } from 'gql/mutations/me';
import { QUERY_ME } from 'gql/queries/me';
import history from 'lib/history';
import i18n from 'lib/i18n';
import { reportError } from 'lib/tracking';

export async function mutationAppLogIn(token, redirectTo = '/') {
  const sanitizedRedirectTo = DOMPurify.sanitize(redirectTo);
  const isURLContainingJSInjection = /^javascript:/i.test(sanitizedRedirectTo);
  const isURLredirectingToWooflash =
    process.env.NODE_ENV === 'development'
      ? /^(\/|(https?:\/\/)?(www\.)?localhost:5000\/).*$/i.test(
          sanitizedRedirectTo,
        )
      : process.env.NODE_ENV === 'staging'
      ? /^(\/|(https?:\/\/)?(www\.)?app\.staging\.wooflash\.com\/).*$/i.test(
          sanitizedRedirectTo,
        )
      : /^(\/|(https?:\/\/)?(www\.)?wooflash\.com\/).*$/i.test(
          sanitizedRedirectTo,
        );
  if (isURLContainingJSInjection || !isURLredirectingToWooflash) {
    const errorMessage = 'Invalid redirect URL';
    reportError('login', errorMessage);
    throw new Error(errorMessage);
  }
  storeJS.set('token', token);
  storeJS.remove('redirectTo');
  const { isAuthenticated: wasAuthenticated } = appVar();
  appVar({
    ...appVar(),
    isAuthenticated: true,
  });
  if (wasAuthenticated) {
    document.location.href = redirectTo;
    return;
  }
  await apollo.query({
    query: QUERY_ME,
  });
  history.push(sanitizedRedirectTo);
}

export async function mutationAppLogOut() {
  storeJS.set('token', null);
  storeJS.remove('token');
  document.location.reload();
}

export async function mutationAppLanguageUpdate(language) {
  const { isAuthenticated } = appVar();
  await i18n.changeLanguage(language);
  document.getElementsByTagName('html')[0].lang = language;
  dayjs.locale(language);
  if (isAuthenticated) {
    await apollo.mutate({
      mutation: ME_UPDATE_LANGUAGE,
      variables: {
        language,
      },
    });
  }
  appVar({
    ...appVar(),
    language,
  });
}

export function mutationAppCookiesConsent({
  cookieConsentBasic,
  cookieConsentFunctionality,
  cookieConsentPerformance,
  cookieConsentTracking,
}) {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + 365);
  storeJS.set('cookieConsentBasic', cookieConsentBasic, exdate.toUTCString());
  storeJS.set(
    'cookieConsentFunctionality',
    cookieConsentFunctionality,
    exdate.toUTCString(),
  );
  storeJS.set(
    'cookieConsentPerformance',
    cookieConsentPerformance,
    exdate.toUTCString(),
  );
  storeJS.set(
    'cookieConsentTracking',
    cookieConsentTracking,
    exdate.toUTCString(),
  );
  appVar({
    ...appVar(),
    cookieConsentBasic,
    cookieConsentFunctionality,
    cookieConsentPerformance,
    cookieConsentTracking,
  });
}

export function mutationAppDashboardDisplayStyle(dashboardDisplayStyle) {
  storeJS.set('dashboardDisplayStyle', dashboardDisplayStyle);
  appVar({
    ...appVar(),
    dashboardDisplayStyle,
  });
}

export function mutationAppRefresh() {
  appVar({
    ...appVar(),
    isAuthenticated: !!storeJS.get('token'),
    language: storeJS.get('i18nextLng') || 'en',
    cookieConsent: storeJS.get('cookieConsent'),
    dashboardDisplayStyle: storeJS.get('dashboardDisplayStyle') || 'list',
  });
}

export function mutationAppStoreQuestionsFromQuizWizard(questions) {
  appVar({
    ...appVar(),
    questionsFromQuizWizard: questions,
  });
}

export function mutationAppClearQuestionsFromQuizWizard() {
  appVar({
    ...appVar(),
    questionsFromQuizWizard: null,
  });
}

export function mutationAppToggleMenu() {
  const { isMenuExpanded } = appVar();
  storeJS.set('isMenuExpanded', !isMenuExpanded);
  appVar({
    ...appVar(),
    isMenuExpanded: !isMenuExpanded,
  });
}
