import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';
import { notify } from 'uikit/Notifications';
import Icon from 'uikit/Icon';

import styles from './DirectLink.module.scss';

const cx = classNames.bind(styles);

const DirectLink = ({ course, group = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Shared/DirectLink',
  });

  function handleCopy() {
    document.getElementById('share-link').select();
    document.execCommand('copy');
    notify('success', t('link-copied'));
  }

  const link = `${process.env.REACT_APP_WEB_URL}/${
    course.moodle.isMoodle
      ? `moodle/${course.accessCode}`
      : group
      ? `join/${course.accessCode}/${group.id}?from=1`
      : `join/${course.accessCode}?from=1`
  }`;

  return (
    <div className={cx('container')}>
      <div className={cx('icon')}>
        <Icon name="link" />
      </div>
      <div className={cx('grow')}>
        <p className={cx('title')}>{t('link')}</p>
        <div className={cx('content')}>
          <p className={cx('link')}>{link}</p>
          <Button aria-label={t('aria-copy-link')} onClick={handleCopy}>
            {t('copy-link')}
          </Button>
          <input
            aria-hidden="true"
            className={cx('hidden')}
            id="share-link"
            readOnly
            type="text"
            value={link}
            tabIndex={-1}
          />
        </div>
      </div>
    </div>
  );
};

DirectLink.propTypes = {
  course: PropTypes.object.isRequired,
  group: PropTypes.object,
};

export default DirectLink;
