import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import UnInvitedWarning from 'components/Course/Shared/UnInvitedWarning/UnInvitedWarning';
import EDITOR_STATUSES from 'constants/editorStatuses';
import PERMISSIONS from 'constants/permissions';
import { useQueryMe } from 'gql/queries/me';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationCourseInvite } from 'gql/mutations/course';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import Modal from 'uikit/Modal';
import { notify } from 'uikit/Notifications';

import styles from './CollaboratorsInvite.module.scss';

const cx = classNames.bind(styles);

const CollaboratorsInvite = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Collaborators/CollaboratorsInvite',
  });

  const mutationCourseInvite = useMutationCourseInvite();

  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();

  const {
    data: { me },
  } = useQueryMe();

  const [unInvited, setUnInvited] = useState([]);

  async function handleCollaboratorsInvite(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      const {
        data: {
          courseInvite: { notSentInvitations },
        },
      } = await mutationCourseInvite({
        ...variables,
        courseId: course.id,
        level: PERMISSIONS.COLLABORATOR,
      });
      setUnInvited(notSentInvitations);
      if (notSentInvitations.length === 0) {
        mutationEditorClear();
        notify('success', t('collaborators-invite-success'));
      }
      trackEvent('collaborators', 'invite');
    } catch (err) {
      form.setStatus(err.message);
      reportError('collaborators', 'invite', err);
    }
  }

  const isCourseInvitationRestricted =
    me?.organization?.settings?.isCourseInvitationRestricted;

  const isModalOpen = editorStatus === EDITOR_STATUSES.COLLABORATORS_INVITE;

  const validationSchema = yup.object().shape({
    usernames: yup
      .string()
      .trim()
      .lowercase()
      .required(t('usernames-error-required')),
  });

  return (
    <Modal isOpen={isModalOpen} onExit={mutationEditorClear}>
      <Formik
        initialValues={{
          usernames: '',
        }}
        onSubmit={handleCollaboratorsInvite}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status }) => (
          <Form className={cx('container')}>
            <Button
              aria-label={t('aria-close')}
              className={cx('close')}
              icon="times"
              onClick={mutationEditorClear}
            />
            <h1 className={cx('title')}>{t('title')}</h1>
            <p className={cx('subtitle')}>{t('subtitle')}</p>
            {status && (
              <p className={cx('error')} role="alert">
                {t('collaborators-invite-error')}
              </p>
            )}
            {isCourseInvitationRestricted && (
              <p className={cx('warning')}>
                {t('warning-invitations-restricted')}
              </p>
            )}
            <InputText
              className={cx('field')}
              icon="at"
              label={t('usernames-label')}
              name="usernames"
            />
            <UnInvitedWarning unInvited={unInvited} />
            <Button
              className={cx('action')}
              intent="primary"
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              {t('confirm-invite-collaborators')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

CollaboratorsInvite.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CollaboratorsInvite;
