import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PERMISSIONS from 'constants/permissions';
import { mutationEditorCollaboratorDelete } from 'gql/mutations/editor';
import Button from 'uikit/Button';

import styles from './CollaboratorCard.module.scss';

const cx = classNames.bind(styles);

const CollaboratorCard = ({ className = null, collaborator, level }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Collaborators/CollaboratorCard',
  });

  const handleCollaboratorDelete = () => {
    mutationEditorCollaboratorDelete(collaborator);
  };

  return (
    <div className={cx('container', className)}>
      <div
        aria-hidden="true"
        className={cx('logo')}
        style={{
          backgroundImage: `url("${collaborator.picture}")`,
        }}
      >
        {!collaborator.picture &&
          `${collaborator.lastName && collaborator.lastName[0]}${
            collaborator.firstName && collaborator.firstName[0]
          }`.toUpperCase()}
        <div
          className={cx('status', {
            isActive: collaborator.isActive,
          })}
        />
      </div>
      <div className={cx('info')}>
        <h3 className={cx('title')}>
          {`${collaborator.lastName.toUpperCase()}, ${collaborator.firstName}`}
        </h3>
        <p className={cx('email')}>{`${
          collaborator.email || collaborator.username
        }`}</p>
      </div>

      {level >= PERMISSIONS.OWNER && collaborator.level < PERMISSIONS.OWNER && (
        <Button
          className={cx('action')}
          icon="trash-alt"
          onClick={handleCollaboratorDelete}
          tooltip={t('delete-collaborator')}
          variant="regular"
        />
      )}
    </div>
  );
};

CollaboratorCard.propTypes = {
  className: PropTypes.string,
  collaborator: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
};

export default CollaboratorCard;
