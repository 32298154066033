import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryCourseStudents } from 'gql/queries/course';
import { useMutationCourseUpdate } from 'gql/mutations/course';
import {
  mutationEditorReset,
  mutationEditorGroupUpdate,
} from 'gql/mutations/editor';
import { useMutationGroupCreate } from 'gql/mutations/group';
import useSiteContext from 'hooks/useSiteContext';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Switch from 'uikit/Switch';

import CourseResetParticipants from './CourseResetParticipants/CourseResetParticipants';
import Empty from './Empty/Empty';
import Finder from './Finder/Finder';
import FinderGAR from './FinderGAR/FinderGAR';
import GroupDelete from './GroupDelete/GroupDelete';
import GroupEdit from './GroupEdit/GroupEdit';
import GroupShare from './GroupShare/GroupShare';
import StudentDelete from './StudentDelete/StudentDelete';

import styles from './Students.module.scss';

const cx = classNames.bind(styles);

const Students = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students',
  });

  const mutationGroupCreate = useMutationGroupCreate();

  const mutationCourseUpdate = useMutationCourseUpdate();

  const { courseId } = useParams();

  const { isGAR } = useSiteContext();

  const { isLoading, data } = useQueryCourseStudents({
    variables: {
      courseId,
    },
  });

  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);

  async function handleGroupCreate() {
    try {
      if (data?.course) {
        const {
          data: { groupCreate },
        } = await mutationGroupCreate({
          courseId,
          title: t('new-group-title'),
        });
        mutationEditorGroupUpdate(groupCreate);
        trackEvent('group', 'create');
      }
    } catch (err) {
      reportError('group', 'create', err);
    }
  }

  async function handleStudentCollaborationToggle() {
    try {
      if (data?.course) {
        await mutationCourseUpdate(data.course, {
          isStudentCollaborationEnabled:
            !data.course.isStudentCollaborationEnabled,
        });
        trackEvent('course', 'update');
      }
    } catch (err) {
      reportError('course', 'update', err);
    }
  }

  const course = data?.course;

  const isEmpty = isLoading
    ? false
    : data.course.students.length === 0 && data.course.groups.length === 0;

  const studentsCount = isLoading
    ? 0
    : data.course.students.length +
      data.course.groups.reduce((p, group) => p + group.students.length, 0);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className={cx('container')}>
        <div className={cx('header')}>
          <h3 className={cx('title')}>
            {t('title')}
            <span className={cx('count')}>{studentsCount}</span>
          </h3>
          <div className={cx('actions')}>
            <Formik
              enableReinitialize
              initialValues={{
                isStudentCollaborationEnabled:
                  course.isStudentCollaborationEnabled,
              }}
              onSubmit={() => null}
            >
              {() => (
                <Form>
                  <Switch
                    className={cx('switch')}
                    label={t('student-collaboration')}
                    name="isStudentCollaborationEnabled"
                    onChange={handleStudentCollaborationToggle}
                  />
                </Form>
              )}
            </Formik>
            <div className={cx('actions-right')}>
              {!isGAR && (
                <Button icon="plus" intent="white" onClick={handleGroupCreate}>
                  {t('create-group')}
                </Button>
              )}
              <CourseResetParticipants course={course} />
            </div>
          </div>
        </div>
        {isEmpty ? (
          <Empty />
        ) : (
          <>
            {isGAR && <FinderGAR course={course} />}
            <Finder course={course} />
          </>
        )}
      </div>
      {!isGAR && (
        <>
          <GroupDelete />
          <GroupEdit />
          <GroupShare course={course} />
        </>
      )}
      <StudentDelete />
    </>
  );
};

export default Students;
