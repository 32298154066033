import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useQueryCourseProgress } from 'gql/queries/course';
import { useQueryMe } from 'gql/queries/me';
import extract from 'helpers/extract';
import useModal from 'hooks/useModal';
import HelpTooltip from 'uikit/HelpTooltip';
import history from 'lib/history';
import Icon from 'uikit/Icon';
import Menu from 'uikit/Menu';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import CourseStudyModal from './CourseStudyModal/CourseStudyModal';
import styles from './CourseStudy.module.scss';

const cx = classNames.bind(styles);

const CourseStudy = ({
  className = null,
  course: partialCourse = null,
  isEditionView = null,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/CourseStudy',
  });

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const { data } = useQueryCourseProgress({
    variables: {
      courseId: partialCourse.id,
    },
  });

  const handleCourseStudy = async (chapterId, setStatus, values) => {
    try {
      setStatus(null);
      const variables = extract(values, validationSchema);
      handleModalClose();
      const method =
        course.isLinear || variables.inOrder
          ? 'order'
          : variables.onlyNew
          ? 'new'
          : variables.allRandom
          ? 'random'
          : 'wooflash';
      const querystring = Object.entries({
        chapterId,
        method,
      })
        .filter(([, v]) => !!v)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      history.push(`/study/${course?.id}?${querystring}`);
    } catch (err) {
      setStatus('500');
    }
  };

  const { data: meData } = useQueryMe();

  const validationSchema = yup.object().shape({
    wooflash: yup.boolean().default(true),
    onlyNew: yup.boolean().default(false),
    allRandom: yup.boolean().default(false),
    inOrder: yup.boolean().default(false),
  });

  const course = data?.course;

  const role = meData?.me.role;

  const hasQuestions =
    course?.questions.length ||
    course?.chapters.some((chapter) => chapter.questions.length);

  const hasNonLinearChapters = course?.chapters.some(
    (chapter) => !chapter.isLinked,
  );

  const isButtonPrimary = role === 'student' && isEditionView && hasQuestions;

  const shouldOpenStudyModal =
    course?.chapters?.length > 1 ||
    (course?.chapters?.length === 1 && course.questions.length > 0);

  return (
    <Formik
      initialValues={{
        wooflash: true,
        onlyNew: false,
        allRandom: false,
        inOrder: false,
      }}
      onSubmit={() => null}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, setStatus, values }) => (
        <>
          {!hasQuestions && (
            <HelpTooltip
              className={cx('disabled-study')}
              tooltip={t('study-course-disabled')}
            />
          )}
          <Form
            className={cx(
              'container',
              {
                editionView: isEditionView,
                highlight: isButtonPrimary,
              },
              className,
            )}
            id="start-study-session-button"
          >
            <button
              className={cx('button', {
                isDisabled: !hasQuestions,
              })}
              data-testid="start-study-session-button"
              onClick={
                shouldOpenStudyModal
                  ? handleModalOpen
                  : () => handleCourseStudy(null, setStatus, values)
              }
              type="button"
            >
              {t('study-course')}
            </button>
            {!course?.isLinear &&
            (course?.questions.length || hasNonLinearChapters) ? (
              <Menu
                isHideOnClickDeactivated
                placement={isEditionView ? 'bottom-end' : 'bottom'}
                toggle={
                  <button
                    aria-label={t('options')}
                    className={cx('action')}
                    type="button"
                  >
                    <Icon className={cx('icon')} name="cog" />
                  </button>
                }
              >
                <div className={cx('options')}>
                  <Switch
                    className={cx('switch')}
                    helper={
                      <Tooltip tooltip={t('wooflash-method-helper')}>
                        <Icon name="info-circle" />
                      </Tooltip>
                    }
                    name="wooflash"
                    label={t('wooflash')}
                    onChange={() =>
                      setFieldValue('wooflash', true) &&
                      setFieldValue('onlyNew', false) &&
                      setFieldValue('allRandom', false) &&
                      setFieldValue('inOrder', false)
                    }
                  />
                  <Switch
                    className={cx('switch')}
                    helper={
                      <Tooltip tooltip={t('in-order-method-helper')}>
                        <Icon name="info-circle" />
                      </Tooltip>
                    }
                    name="inOrder"
                    label={t('in-order')}
                    onChange={() =>
                      values.inOrder
                        ? setFieldValue('wooflash', true) &&
                          setFieldValue('onlyNew', false) &&
                          setFieldValue('allRandom', false) &&
                          setFieldValue('inOrder', false)
                        : setFieldValue('wooflash', false) &&
                          setFieldValue('onlyNew', false) &&
                          setFieldValue('allRandom', false) &&
                          setFieldValue('inOrder', true)
                    }
                  />
                  <Switch
                    className={cx('switch')}
                    name="allRandom"
                    label={t('random-order')}
                    onChange={() =>
                      values.allRandom
                        ? setFieldValue('wooflash', true) &&
                          setFieldValue('onlyNew', false) &&
                          setFieldValue('allRandom', false) &&
                          setFieldValue('inOrder', false)
                        : setFieldValue('wooflash', false) &&
                          setFieldValue('onlyNew', false) &&
                          setFieldValue('allRandom', true) &&
                          setFieldValue('inOrder', false)
                    }
                  />
                  <Switch
                    className={cx('switch')}
                    name="onlyNew"
                    label={t('new-only')}
                    onChange={() =>
                      values.onlyNew
                        ? setFieldValue('wooflash', true) &&
                          setFieldValue('onlyNew', false) &&
                          setFieldValue('allRandom', false) &&
                          setFieldValue('inOrder', false)
                        : setFieldValue('wooflash', false) &&
                          setFieldValue('onlyNew', true) &&
                          setFieldValue('allRandom', false) &&
                          setFieldValue('inOrder', false)
                    }
                  />
                  {course?.chapters.filter((chapter) => chapter.isLinked)
                    .length > 0 && (
                    <p className={cx('information')}>
                      {t('information-progressive-chapters')}
                    </p>
                  )}
                </div>
              </Menu>
            ) : (
              !isEditionView && (
                <button
                  className={cx('action')}
                  onClick={
                    shouldOpenStudyModal
                      ? handleModalOpen
                      : () => handleCourseStudy(null, setStatus, values)
                  }
                  type="button"
                  tabIndex={-1}
                >
                  <Icon name="chevron-right" />
                </button>
              )
            )}
          </Form>
          <CourseStudyModal
            course={course}
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
            handleCourseStudy={(chapterId) =>
              handleCourseStudy(chapterId, setStatus, values)
            }
          />
        </>
      )}
    </Formik>
  );
};

CourseStudy.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object,
  isEditionView: PropTypes.bool,
};

export default CourseStudy;
