import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Cover from './Cover/Cover';
import Exam from './Exam/Exam';
import Progress from './Progress/Progress';
import styles from './Student.module.scss';

const cx = classNames.bind(styles);

const Student = ({ course }) => (
  <div>
    <Cover className={cx('cover')} />
    {course.isExam ? <Exam /> : <Progress />}
  </div>
);

Student.propTypes = {
  course: PropTypes.object.isRequired,
};

export default Student;
