import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import Button from 'uikit/Button';

import styles from './CourseProgress.module.scss';

const cx = classNames.bind(styles);

const CourseProgress = ({
  className = null,
  course: courseWithoutStats,
  isStudent = false,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/Progress/CourseProgress',
  });

  function getCourseWithStats() {
    const questions = [].concat(
      courseWithoutStats.questions,
      ...courseWithoutStats.chapters.map((c) => c.questions),
    );
    const mastery =
      Math.round(
        (questions.reduce((m, q) => m + q.studyStats.mastery, 0) /
          (courseWithoutStats.masteryThreshold * questions.length)) *
          100,
      ) || 0;
    const progression =
      Math.round(
        (questions.filter((q) => q.studyStats.attempted).length /
          questions.length) *
          100,
      ) || 0;
    const success =
      Math.round(
        (questions.filter((q) => q.studyStats.succeeded).length /
          questions.filter((q) => q.studyStats.attempted).length) *
          100,
      ) || 0;
    return { ...courseWithoutStats, mastery, progression, success };
  }

  const course = isStudent ? getCourseWithStats() : courseWithoutStats;

  return (
    <div className={cx(className, 'container')}>
      <div className={cx('header', { isStudent })}>
        <div className={cx('title-container')}>
          <div className={cx('title-icon')}>%</div>
          <h2 className={cx('title')}>
            {t(isStudent ? 'title-student' : 'title')}
          </h2>
        </div>
        {isStudent && (
          <Button
            as="a"
            className={cx('download-button')}
            href={`${
              process.env.REACT_APP_API_URL
            }/rest/study-stats/excel-student?courseId=${
              course.id
            }&authToken=${storeJS.get('token')}`}
            icon="download"
            target="blank"
          >
            <span className={cx('download-text')}>
              {t('download-statistics')}
            </span>
          </Button>
        )}
      </div>
      <div className={cx('stat-container')}>
        <div className={cx('content', 'progression')}>
          <div
            aria-label={t('aria-progression', {
              progression: course.progression,
            })}
            className={cx('graph-container')}
          >
            <svg className={cx('graph')} viewBox="0 0 160 160">
              <circle
                cx="80"
                cy="80"
                r="15.91549430918954"
                fill="transparent"
              />
              <circle
                cx="80"
                cy="80"
                r="60"
                fill="transparent"
                stroke="#fff"
                strokeWidth="20"
              />
              <circle
                cx="80"
                cy="80"
                r="60"
                fill="transparent"
                stroke="#9f7cff"
                strokeWidth="20"
                strokeDasharray={`${(course.progression / 100) * 377} ${
                  377 - (course.progression / 100) * 377
                }`}
                strokeDashoffset="94.25"
              />
              <circle cx="80" cy="20" r="10" fill="#9f7cff" />
              <text
                className={cx('percentage')}
                dominantBaseline="middle"
                textAnchor="middle"
                x="50%"
                y="50%"
              >
                {`${course.progression}%`}
              </text>
            </svg>
            <div className={cx('name')}>
              <p>{t('progression-name')}</p>
              <p className={cx('explanation')}>{t('progression-tooltip')}</p>
            </div>
          </div>
        </div>
        <div className={cx('content', 'success')}>
          <div
            aria-label={t('aria-success', {
              success: course.success,
            })}
            className={cx('graph-container')}
          >
            <svg className={cx('graph')} viewBox="0 0 160 160">
              <circle
                cx="80"
                cy="80"
                r="15.91549430918954"
                fill="transparent"
              />
              <circle
                cx="80"
                cy="80"
                r="60"
                fill="transparent"
                stroke="#fff"
                strokeWidth="20"
              />
              <circle
                cx="80"
                cy="80"
                r="60"
                fill="transparent"
                stroke="#1bc489"
                strokeWidth="20"
                strokeDasharray={`${(course.success / 100) * 377} ${
                  377 - (course.success / 100) * 377
                }`}
                strokeDashoffset="94.25"
              />
              <circle cx="80" cy="20" r="10" fill="#1bc489" />
              <text
                className={cx('percentage')}
                dominantBaseline="middle"
                textAnchor="middle"
                x="50%"
                y="50%"
              >
                {`${course.success}%`}
              </text>
            </svg>
            <div className={cx('name')}>
              <p>{t('success-name')}</p>
              <p className={cx('explanation')}>{t('success-tooltip')}</p>
            </div>
          </div>
        </div>
        <div className={cx('content', 'mastery')}>
          <div
            aria-label={t('aria-mastery', {
              mastery: course.mastery,
            })}
            className={cx('graph-container')}
          >
            <svg className={cx('graph')} viewBox="0 0 160 160">
              <circle
                cx="80"
                cy="80"
                r="15.91549430918954"
                fill="transparent"
              />
              <circle
                cx="80"
                cy="80"
                r="60"
                fill="transparent"
                stroke="#fff"
                strokeWidth="20"
              />
              <circle
                cx="80"
                cy="80"
                r="60"
                fill="transparent"
                stroke="#f8be4a"
                strokeWidth="20"
                strokeDasharray={`${(course.mastery / 100) * 377} ${
                  377 - (course.mastery / 100) * 377
                }`}
                strokeDashoffset="94.25"
              />
              <circle cx="80" cy="20" r="10" fill="#f8be4a" />
              <text
                className={cx('percentage')}
                dominantBaseline="middle"
                textAnchor="middle"
                x="50%"
                y="50%"
              >
                {`${course.mastery}%`}
              </text>
            </svg>
            <div className={cx('name')}>
              <p>{t('mastery-name')}</p>
              <p className={cx('explanation')}>{t('mastery-tooltip')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CourseProgress.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  isStudent: PropTypes.bool,
};

export default CourseProgress;
