import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQueryCourseGames } from 'gql/queries/course';
import useModal from 'hooks/useModal';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal';

import imagePlay from './images/play.svg';
import styles from './GamePlay.module.scss';

const cx = classNames.bind(styles);

const GamePlay = ({ className, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Student/Cover/GamePlay',
  });

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const { data } = useQueryCourseGames({
    variables: {
      courseId: course.id,
    },
    skip: !isModalOpen,
  });

  const games = data?.course.games;

  return (
    <>
      <button
        className={cx(className, 'play-button')}
        onClick={handleModalOpen}
        type="button"
      >
        {t('play-game')}
        <Icon className={cx('chevron')} name="chevron-right" />
      </button>
      <Modal isOpen={isModalOpen} onExit={handleModalClose}>
        <div className={cx('modal')}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleModalClose}
          />
          <img
            alt=""
            aria-hidden="true"
            className={cx('image')}
            src={imagePlay}
          />
          <h1 className={cx('title')}>{t('title')}</h1>
          {games?.map((game) => (
            <Button
              as={Link}
              className={cx('action')}
              icon="gamepad"
              intent="primary"
              key={game.id}
              size="large"
              to={`/play/${game.id}`}
            >
              {game.title}
            </Button>
          ))}
        </div>
      </Modal>
    </>
  );
};

GamePlay.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default GamePlay;
