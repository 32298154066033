import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import imageStudents from './images/students.svg';
import styles from './Empty.module.scss';

const cx = classNames.bind(styles);

const Empty = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students/Empty',
  });

  return (
    <div className={cx('container')}>
      <img
        alt=""
        aria-hidden="true"
        className={cx('image')}
        src={imageStudents}
      />
      <p>{t('no-students')}</p>
    </div>
  );
};

export default Empty;
