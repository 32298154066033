import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PERMISSIONS from 'constants/permissions';
import { useMutationCoursePin } from 'gql/mutations/course';
import tutorialIllustrationsChecker from 'helpers/tutorialIllustrationsChecker';
import TutorialIllustration1 from 'images/tutorials/illustrationTutorial1.svg';
import TutorialIllustration2 from 'images/tutorials/illustrationTutorial2.svg';
import Icon from 'uikit/Icon';
import Tooltip from 'uikit/Tooltip';

import Illustration1 from './images/illustration1.svg';
import Illustration2 from './images/illustration2.svg';
import Illustration3 from './images/illustration3.svg';
import Illustration4 from './images/illustration4.svg';
import styles from './CourseCard.module.scss';

const cx = classNames.bind(styles);

const IMAGES = [Illustration1, Illustration2, Illustration3, Illustration4];

const CourseCard = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard/CourseCard',
  });

  const mutationCoursePin = useMutationCoursePin();

  async function handleCoursePin(evt) {
    try {
      evt.preventDefault();
      evt.stopPropagation();
      await mutationCoursePin(course);
    } catch (err) {
      // empty
    }
  }

  // Extracts the first 8 characters of the course ID, removes any non-digit characters,
  // converts the result to an integer, and returns the remainder when divided by 4.
  // If the result is 0, it defaults to 0.
  // This is used to determine randomly which illustration to display on the course card based on its ID.
  const illustrationIndex =
    parseInt(course.id.substring(0, 8).replace(/\D/g, ''), 10) % 4 || 0;

  const { needTutorialIllustration1, needTutorialIllustration2 } =
    tutorialIllustrationsChecker(course.id);

  return (
    <>
      <Link
        className={cx('card-container', `theme-${course.themeBackground}`)}
        to={`/course/${course.id}/`}
      >
        <img
          className={cx('picture')}
          alt=""
          src={
            needTutorialIllustration1
              ? TutorialIllustration1
              : needTutorialIllustration2
              ? TutorialIllustration2
              : IMAGES[illustrationIndex]
          }
        />
        <p className={cx('title')}>{course.title}</p>
        <div className={cx('footer')}>
          <p className={cx('owner')}>
            {t('author', {
              firstName: course.owner.firstName,
              lastName: course.owner.lastName,
            })}
          </p>
          {course.level >= PERMISSIONS.COLLABORATOR && (
            <p className={cx('participants')}>
              <Icon
                className={cx('participants-icon')}
                name="chalkboard-user"
              />
            </p>
          )}
        </div>
      </Link>
      <Tooltip
        tooltip={course.isPinned ? t('remove-favorite') : t('add-favorite')}
      >
        <button
          aria-label={
            course.isPinned
              ? t('aria-remove-favorite', {
                  name: course.title,
                })
              : t('aria-add-favorite', {
                  name: course.title,
                })
          }
          aria-checked={course.isPinned}
          className={cx('pin')}
          onClick={handleCoursePin}
          tooltip={course.isPinned ? t('remove-favorite') : t('add-favorite')}
          type="button"
          role="checkbox"
        >
          <Icon name="star" variant={course.isPinned ? 'solid' : 'regular'} />
        </button>
      </Tooltip>
    </>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseCard;
