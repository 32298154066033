import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'uikit/Button';

import styles from './BackButton.module.scss';

const cx = classNames.bind(styles);

const BackButton = ({ course = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course',
  });

  return (
    <Button
      aria-label={t('back')}
      as={Link}
      className={cx('back')}
      icon="arrow-left"
      intent="white"
      to={{
        pathname: '/',
        state: {
          currentDisplayedCourseId: course?.id,
        },
      }}
    />
  );
};

BackButton.propTypes = {
  course: PropTypes.object,
};

export default BackButton;
