import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'uikit/Icon';

import StudentCard from '../StudentCard/StudentCard';
import styles from './GroupCard.module.scss';

const cx = classNames.bind(styles);

const GroupCard = ({ className = null, course, group }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students/Finder/GroupCard',
  });

  const [isExpanded, setExpanded] = useState(false);

  function handleGroupExpandedToggle(evt) {
    evt.stopPropagation();
    setExpanded((state) => !state);
  }

  return (
    <div className={cx('container', className)}>
      <div className={cx('group')}>
        <button
          aria-label={t(`aria-${isExpanded ? 'close' : 'open'}-group`)}
          className={cx('icon')}
          onClick={handleGroupExpandedToggle}
          type="button"
        >
          {isExpanded ? (
            <Icon name="chevron-down" />
          ) : (
            <Icon name="chevron-right" />
          )}
        </button>
        <p className={cx('title')}>{group.title}</p>
      </div>
      {isExpanded && (
        <div className={cx('students')}>
          {group.students.map((student) => (
            <StudentCard
              className={cx('student')}
              course={course}
              student={student}
            />
          ))}
        </div>
      )}
    </div>
  );
};

GroupCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
};

export default GroupCard;
