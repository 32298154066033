import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  mutationEditorGroupDelete,
  mutationEditorGroupShare,
  mutationEditorGroupUpdate,
} from 'gql/mutations/editor';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import Students from '../Students/Students';
import styles from './GroupCard.module.scss';

const cx = classNames.bind(styles);

const GroupCard = ({ className = null, course, group }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students/Finder/GroupCard',
  });

  const [isExpanded, setExpanded] = useState(false);

  function handleGroupExpandedToggle(evt) {
    evt.stopPropagation();
    setExpanded((state) => !state);
  }

  function handleGroupUpdate() {
    mutationEditorGroupUpdate(group);
  }

  function handleGroupDelete() {
    mutationEditorGroupDelete(group);
  }

  function handleGroupShare() {
    mutationEditorGroupShare(group);
  }

  return (
    <Droppable droppableId={group.id} type="student">
      {(providedStudents, snapshotStudents) => (
        <div
          className={cx(
            'container',
            {
              isDraggingOver: snapshotStudents.isDraggingOver,
            },
            className,
          )}
        >
          <div
            ref={providedStudents.innerRef}
            {...providedStudents.droppableProps}
          >
            <div className={cx('group')}>
              <button
                aria-label={t(`aria-${isExpanded ? 'close' : 'open'}-group`)}
                className={cx('icon')}
                onClick={handleGroupExpandedToggle}
                type="button"
              >
                <Icon
                  name={isExpanded ? 'chevron-down' : 'chevron-right'}
                  variant="solid"
                />
              </button>
              <div className={cx('group-icon')}>
                <Icon name="screen-users" />
              </div>
              <p className={cx('title')}>{group.title}</p>
              <Button
                className={cx('action')}
                onClick={handleGroupShare}
                icon="plus"
                size="small"
              >
                {t('invite-students')}
              </Button>
              {!course.isExam && (
                <Button
                  as={Link}
                  className={cx('action')}
                  icon="chart-bar"
                  size="small"
                  to={{
                    pathname: `/course/${course.id}/statistics`,
                    state: {
                      type: 'group',
                      groupId: group.id,
                    },
                  }}
                  tooltip={t('statistics')}
                />
              )}
              <Menu
                placement="bottom-end"
                toggle={
                  <Button
                    className={cx('action')}
                    icon="ellipsis-v"
                    size="small"
                    tooltip={t('options')}
                  />
                }
              >
                <MenuItem icon="edit" onClick={handleGroupUpdate}>
                  {t('rename-group')}
                </MenuItem>
                <MenuItem
                  icon="trash-alt"
                  onClick={handleGroupDelete}
                  variant="regular"
                >
                  {t('delete-group')}
                </MenuItem>
              </Menu>
            </div>
            {isExpanded && (
              <div
                className={cx('students', {
                  isDraggingOver: snapshotStudents.isDraggingOver,
                })}
              >
                <Students course={course} groupId={group.id} />
                {providedStudents.placeholder}
              </div>
            )}
          </div>
        </div>
      )}
    </Droppable>
  );
};

GroupCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
};

export default GroupCard;
