import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import Comments from 'components/Shared/Comment/Comments/Comments';
import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryCourseContent } from 'gql/queries/course';
import { useQueryLocalEditor } from 'gql/queries/local';
import { mutationEditorReset } from 'gql/mutations/editor';
import { useURLToStateQuestionCommentOpenQuestion } from 'hooks/useURLToState';

import QuestionEdit from '../../Shared/QuestionEdit/QuestionEdit';
import Actions from './Actions/Actions';
import AggregatedAnswers from './AggregatedAnswers/AggregatedAnswers';
import ChapterEdit from './ChapterEdit/ChapterEdit';
import ChapterMove from './ChapterMove/ChapterMove';
import Empty from './Empty/Empty';
import FileImport from './FileImport/FileImport';
import Finder from './Finder/Finder';
import Multi from './Multi/Multi';
import QuestionImport from './QuestionImport/QuestionImport';
import QuestionMove from './QuestionMove/QuestionMove';
import QuestionsMove from './QuestionsMove/QuestionsMove';
import StudentErrors from './StudentErrors/StudentErrors';
import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor',
  });

  const { courseId } = useParams();

  const {
    data: {
      editor: { questionsSelected },
    },
  } = useQueryLocalEditor();

  const { isLoading, data } = useQueryCourseContent({
    variables: {
      courseId,
    },
  });

  useEffect(() => {
    const getHeader = () => {
      const header = document.getElementById('editor-header');
      if (header) {
        const headerPosition = header.getBoundingClientRect();
        if (
          headerPosition.top <= 0 &&
          !header.classList.contains('sticky-header')
        ) {
          header.classList.add('sticky-header');
        }
        if (
          headerPosition.top > 0 &&
          header.classList.contains('sticky-header')
        ) {
          header.classList.remove('sticky-header');
        }
      }
    };

    window.addEventListener('scroll', getHeader);
    return () => window.removeEventListener('scroll', getHeader);
  }, []);

  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);

  useURLToStateQuestionCommentOpenQuestion(isLoading, data);

  const isEmpty = isLoading
    ? false
    : data.course.questionsToApprove.length === 0 &&
      data.course.questions.length === 0 &&
      data.course.chapters.length === 0 &&
      data.course.files.length === 0;

  const nQuestions = isLoading
    ? 0
    : data.course.questions.length +
      data.course.chapters.reduce(
        (p, chapter) => p + chapter.questions.length,
        0,
      );

  const course = data?.course;

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className={cx('container')}>
        <div className={cx('header')} id="editor-header">
          <h3 className={cx('title')}>
            {t('title')}
            <span className={cx('count')}>{nQuestions}</span>
          </h3>
          {questionsSelected.length >= 1 ? (
            <Multi course={course} />
          ) : (
            <Actions className={cx('actions')} course={course} />
          )}
        </div>
        {course.isExam && course.examStartTime === course.examStopTime && (
          <Link className={cx('warning')} to={`/course/${course.id}/settings`}>
            {t('warning-settings')}
          </Link>
        )}
        {isEmpty ? (
          <Empty />
        ) : (
          <Finder className={cx('finder')} course={course} />
        )}
      </div>
      <AggregatedAnswers course={course} />
      <ChapterEdit course={course} />
      <ChapterMove />
      <FileImport course={course} />
      <Comments course={course} />
      <QuestionEdit course={course} />
      <QuestionImport course={course} />
      <QuestionMove />
      <QuestionsMove course={course} />
      <StudentErrors course={course} />
    </>
  );
};

export default Editor;
