import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import LoadingScreen from 'components/Shared/LoadingScreen';
import PERMISSIONS from 'constants/permissions';
import { useQueryCourseCollaborators } from 'gql/queries/course';
import {
  mutationEditorReset,
  mutationEditorCollaboratorsInvite,
} from 'gql/mutations/editor';
import Button from 'uikit/Button';

import CollaboratorCard from './CollaboratorCard/CollaboratorCard';
import CollaboratorsInvite from './CollaboratorsInvite/CollaboratorsInvite';
import CollaboratorDelete from './CollaboratorDelete/CollaboratorDelete';
import styles from './Collaborators.module.scss';

const cx = classNames.bind(styles);

const Collaborators = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Collaborators',
  });

  const { courseId } = useParams();

  const { isLoading, data } = useQueryCourseCollaborators({
    variables: {
      courseId,
    },
  });

  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);

  const course = data?.course;

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <div className={cx('container', className)}>
        <div className={cx('header')}>
          <h3 className={cx('title')}>
            {t('title')}
            <span className={cx('count')}>
              {course.collaborators.length + 1}
            </span>
          </h3>
          <Button
            icon="plus"
            intent="white"
            onClick={mutationEditorCollaboratorsInvite}
          >
            {t('invite-collaborators')}
          </Button>
        </div>
        <CollaboratorCard
          className={cx('collaborator')}
          collaborator={course.owner}
          level={course.level}
        />
        {course.collaborators.map((collaborator) => (
          <CollaboratorCard
            className={cx('collaborator')}
            collaborator={collaborator}
            level={course.level}
            key={collaborator.id}
          />
        ))}
      </div>
      <CollaboratorsInvite course={course} />
      {course.level >= PERMISSIONS.OWNER && <CollaboratorDelete />}
    </>
  );
};

Collaborators.propTypes = {
  className: PropTypes.string,
};

export default Collaborators;
