import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AdaptiveImage from './images/adaptive.svg';
import LinearImage from './images/linear.svg';
import ExamImage from './images/examen.svg';
import styles from './CourseTypes.module.scss';

const cx = classNames.bind(styles);

const CourseTypes = ({
  className = null,
  current = null,
  handleTypeUpdate,
  isInSettings = false,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/CourseMethod',
  });

  return (
    <div className={cx('choices', className)}>
      <button
        className={cx('choice', 'adaptive', {
          isInSettings,
          isActive: current === 'adaptive',
          isInactive: current !== 'adaptive',
        })}
        data-testid="adaptive-course-button"
        onClick={() => handleTypeUpdate('adaptive')}
        type="button"
      >
        <div className={cx('recommended')}>{t('recommended')}</div>
        <div className={cx('picture-container')}>
          <img className={cx('picture')} alt="" src={AdaptiveImage} />
        </div>
        <div className={cx('description')}>
          <h2 className={cx('choice-title')}>{t('adaptive-title')}</h2>
          <p className={cx('choice-subtitle')}>{t('adaptive-subtitle')}</p>
        </div>
      </button>
      <button
        className={cx('choice', 'linear', {
          isInSettings,
          isActive: current === 'linear',
          isInactive: current !== 'linear',
        })}
        onClick={() => handleTypeUpdate('linear')}
        type="button"
      >
        <div className={cx('picture-container')}>
          <img className={cx('picture')} alt="" src={LinearImage} />
        </div>
        <div className={cx('description')}>
          <h2 className={cx('choice-title')}>{t('linear-title')}</h2>
          <p className={cx('choice-subtitle')}>{t('linear-subtitle')}</p>
        </div>
      </button>
      <button
        className={cx('choice', 'exam', {
          isInSettings,
          isActive: current === 'exam',
          isInactive: current !== 'exam',
        })}
        onClick={() => handleTypeUpdate('exam')}
        type="button"
      >
        <div className={cx('picture-container')}>
          <img className={cx('picture')} alt="" src={ExamImage} />
        </div>
        <div className={cx('description')}>
          <h2 className={cx('choice-title')}>{t('exam-title')}</h2>
          <p className={cx('choice-subtitle')}>{t('exam-subtitle')}</p>
        </div>
      </button>
    </div>
  );
};

CourseTypes.propTypes = {
  className: PropTypes.string,
  current: PropTypes.string,
  handleTypeUpdate: PropTypes.func.isRequired,
  isInSettings: PropTypes.bool,
};

export default CourseTypes;
