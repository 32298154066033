import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CourseJoin from 'components/Shared/CourseJoin';
import LoadingScreen from 'components/Shared/LoadingScreen';
import MenuContainer from 'components/Shared/MenuContainer';
import PERMISSIONS from 'constants/permissions';
import { useMutationMeTag } from 'gql/mutations/me';
import { useQueryCoursesMe } from 'gql/queries/course';
import { useQueryFoldersMe } from 'gql/queries/folder';
import { useQueryLocalApp } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import useModal from 'hooks/useModal';
import useOnError from 'hooks/useOnError';
import useSiteContext from 'hooks/useSiteContext';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import Icon from 'uikit/Icon';

import Actions from './Actions/Actions';
import CourseCard from './CourseCard/CourseCard';
import CoursesGAR from './CoursesGAR/CoursesGAR';
import Empty from './Empty/Empty';
import Finder from './Finder/Finder';
import FolderDelete from './FolderDelete/FolderDelete';
import FolderEdit from './FolderEdit/FolderEdit';
import styles from './Dashboard.module.scss';

const cx = classNames.bind(styles);

const Dashboard = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard',
  });

  const mutationMeTag = useMutationMeTag();

  const { isEditionDisabled, isGAR, isRoleStudent } = useSiteContext();

  const {
    isModalOpen: isCourseJoinDisplayed,
    handleModalOpen: handleCourseJoinOpen,
    handleModalClose: handleCourseJoinClose,
  } = useModal();

  const {
    data: {
      app: { dashboardDisplayStyle },
    },
  } = useQueryLocalApp();

  const { data: dataMe } = useQueryMe();

  const { error, isLoading, data } = useQueryCoursesMe();

  const {
    error: folderError,
    isLoading: isLoadingFolders,
    data: dataFolders,
  } = useQueryFoldersMe();

  useOnError(error, () => {
    history.push('/error');
  });

  useOnError(folderError, () => {
    history.push('/error');
  });

  const [search, setSearch] = useState('');

  const [filter, setFilter] = useState('all');

  const handleSearchChange = async (evt) => {
    try {
      setSearch(evt.target.value);
      const isTutorialPopupDisplayed = !dataMe.me.tags.includes('tutorials');
      if (isTutorialPopupDisplayed) {
        await mutationMeTag(['tutorials']);
        trackEvent('tag', 'tutorials');
      }
    } catch (err) {
      reportError('tag', 'tutorials', err);
    }
  };

  const folders = isLoadingFolders ? [] : dataFolders.foldersMe;

  const unFilteredCourses = isLoading ? [] : data.coursesMe;

  const isEmpty = unFilteredCourses.length === 0 && !isGAR;

  const courses = unFilteredCourses
    .filter((course) =>
      filter === 'teacher'
        ? course.level >= PERMISSIONS.COLLABORATOR
        : filter === 'student'
        ? course.level === PERMISSIONS.STUDENT
        : true,
    )
    .filter(
      (course) =>
        !search ||
        course.title.toLowerCase().includes(search.trim().toLowerCase()) ||
        `${course.owner.firstName.toLowerCase()} ${course.owner.lastName.toLowerCase()}`.includes(
          search.trim().toLowerCase(),
        ),
    );

  const pinnedCourses = unFilteredCourses.filter((course) => course.isPinned);

  const me = dataMe?.me;

  const courseSectionTitle = `${
    search &&
    t('search-in', {
      search,
    })
  }
  ${t(`title-${filter}`)}`;

  return (
    <MenuContainer
      header={
        !isEditionDisabled && (
          <>
            <h1 className={cx('dashboard-title')}>
              {t('title')} {isGAR && `(${me.organization?.name})` }
            </h1>
            <div className={cx('search')}>
              <Icon name="search" />
              <input
                aria-label={t('aria-search')}
                className={cx('search-input')}
                onChange={handleSearchChange}
                placeholder={t('search-placeholder')}
                size="15"
                type="text"
                value={search}
              />
            </div>
          </>
        )
      }
      title={
        <p className={cx('dashboard-hello')}>
          {t('hello')}
          <span>{me.firstName}</span> 👋
        </p>
      }
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {pinnedCourses.length > 0 && !isEditionDisabled && (
            <div className={cx('quick')}>
              <div className={cx('section-title-container')}>
                <h2 className={cx('section-title')}>{t('quick-access')}</h2>
                <p className={cx('number')}>{pinnedCourses.length}</p>
              </div>
              <div className={cx('favorites-placeholder')}>
                <div className={cx('favorites-container')}>
                  <div className={cx('favorites')}>
                    {pinnedCourses.map((course) => (
                      <CourseCard course={course} key={course.id} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={cx('section-title-container-small')}>
            {!isEditionDisabled && (
              <h2 className={cx('section-title')}>{courseSectionTitle}</h2>
            )}
            <Actions
              filter={filter}
              handleCourseJoinOpen={handleCourseJoinOpen}
              handleFilterSet={setFilter}
              isEmpty={isEmpty}
            />
          </div>
          {isEmpty ? (
            isEditionDisabled ? null : (
              <Empty />
            )
          ) : (
            <div className={cx('courses', `style-${dashboardDisplayStyle}`)}>
              {dashboardDisplayStyle === 'list' ? (
                <Finder courses={courses} folders={folders} />
              ) : (
                courses.map((course) => (
                  <CourseCard course={course} key={course.id} />
                ))
              )}
            </div>
          )}
          {isGAR && isRoleStudent && <CoursesGAR />}
        </>
      )}
      <CourseJoin
        isModalOpen={isCourseJoinDisplayed}
        handleModalClose={handleCourseJoinClose}
      />
      <FolderEdit />
      <FolderDelete />
    </MenuContainer>
  );
};

export default Dashboard;
