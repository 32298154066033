import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import * as yup from 'yup';

import LoadingScreen from 'components/Shared/LoadingScreen';
import CourseTypes from './CourseTypes/CourseTypes';
import COURSE_CONFIG from 'constants/courseConfig';
import { useMutationCourseUpdate } from 'gql/mutations/course';
import { useQueryCourse } from 'gql/queries/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputDateTime from 'uikit/InputDateTime';
import InputNumber from 'uikit/InputNumber';
import InputRich from 'uikit/InputRich';
import InputText from 'uikit/InputText';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Settings.module.scss';

const cx = classNames.bind(styles);

const Settings = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Settings',
  });

  const { courseId } = useParams();

  const mutationCourseUpdate = useMutationCourseUpdate();

  const { isLoading, data } = useQueryCourse({
    variables: {
      courseId,
    },
  });

  async function handleCourseUpdate(values, form) {
    try {
      form.setStatus(null);
      const formattedValues =
        values.nQuestionsByExam === ''
          ? { ...values, nQuestionsByExam: 0 }
          : values;
      const variables = extract(formattedValues, validationSchema);
      await mutationCourseUpdate(data.course, variables);
      trackEvent('course', 'update');
    } catch (err) {
      form.setStatus('500');
      reportError('course', 'update', err);
    }
  }

  const handleTypeUpdate = (setFieldValue) => (type) => {
    setFieldValue('isLinear', type === 'linear');
    setFieldValue('isExam', type === 'exam');
  };

  const validationSchema = yup.object().shape({
    isLinear: yup.boolean(),
    masteryThreshold: yup
      .number()
      .integer()
      .default(1)
      .min(1, t('mastery-threshold-error-min')),
    isExam: yup.boolean().nullable(),
    examStartTime: yup
      .date()
      .when('isExam', {
        is: true,
        then: yup.date().nullable().required(t('start-time-error-required')),
        otherwise: yup.date().nullable(),
      })
      .min(COURSE_CONFIG.minDateLimit, t('limit-min-date'))
      .max(COURSE_CONFIG.maxDateLimit, t('limit-max-date')),
    examStopTime: yup
      .date()
      .when('isExam', {
        is: true,
        then: yup
          .date()
          .nullable()
          .required(t('stop-time-error-required'))
          .min(yup.ref('examStartTime'), t('stop-time-error-after-start')),
        otherwise: yup.date().nullable(),
      })
      .max(COURSE_CONFIG.maxDateLimit, t('limit-max-date')),
    examDurationMinutes: yup
      .number()
      .integer(t('duration-error-required'))
      .min(0, t('duration-error-required'))
      .transform((v) => v || 0),
    isLinearExam: yup.boolean().nullable(),
    examInRandomOrder: yup.boolean().nullable(),
    nQuestionsByExam: yup.number().nullable().min(0),
    examInstructions: yup.string().nullable().trim(),
    isExamReviewEnabled: yup.boolean().nullable(),
    deadline: yup
      .date()
      .nullable()
      .min(COURSE_CONFIG.minDateLimit, t('limit-min-date'))
      .max(COURSE_CONFIG.maxDateLimit, t('limit-max-date')),
  });

  const course = data?.course;

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Formik
      enableReinitialize
      initialValues={{
        ...course,
        masteryThresholdInput: course.masteryThreshold,
        deadline: course.deadline,
      }}
      onSubmit={handleCourseUpdate}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, setFieldValue, status, values }) => (
        <Form className={cx('container')}>
          <div className={cx('header')}>
            <h3 className={cx('title')}>
              {t('title')}
              {dirty && <span className={cx('badge')}>{t('not-save')}</span>}
            </h3>
            <div>
              <Button
                data-testid="save-settings-button"
                intent={dirty ? 'primary' : 'default'}
                isDisabled={!dirty}
                isLoading={isSubmitting}
                type="submit"
              >
                {t('save')}
              </Button>
            </div>
          </div>
          {status === '500' && (
            <p className={cx('error')} role="alert">
              {t('update-error')}
            </p>
          )}
          <CourseTypes
            className={cx('choices')}
            current={
              values.isExam ? 'exam' : values.isLinear ? 'linear' : 'adaptive'
            }
            handleTypeUpdate={handleTypeUpdate(setFieldValue)}
            isInSettings
          />
          {!values.isExam && (
            <div className={cx('content')}>
              <p className={cx('section-title')}>{t('section-study')}</p>
              <InputNumber
                className={cx('field')}
                helper={
                  <Tooltip tooltip={t('mastery-helper')}>
                    <Icon name="info-circle" />
                  </Tooltip>
                }
                label={t('mastery-label')}
                name="masteryThreshold"
              />
            </div>
          )}
          {!values.isExam && (
            <div className={cx('content')}>
              <p className={cx('section-title')}>{t('section-deadline')}</p>
              <InputDateTime name="deadline" />
            </div>
          )}
          {values.isExam && (
            <>
              <div className={cx('content')}>
                <p className={cx('section-title')}>{t('section-time')}</p>
                <InputDateTime
                  className={cx('field')}
                  label={t('start-time-label')}
                  name="examStartTime"
                />
                <InputDateTime
                  className={cx('field')}
                  label={t('stop-time-label')}
                  name="examStopTime"
                />
                <InputText
                  className={cx('field')}
                  label={t('duration-label')}
                  name="examDurationMinutes"
                  type="number"
                />
              </div>
              <div className={cx('content')}>
                <p className={cx('section-title')}>{t('section-question')}</p>
                <Switch
                  className={cx('field')}
                  label={t('is-linear-exam')}
                  name="isLinearExam"
                />
                <Switch
                  className={cx({ field: !values.examInRandomOrder })}
                  label={t('random-label')}
                  name="examInRandomOrder"
                />
                {values.examInRandomOrder && (
                  <div className={cx('field', 'questions-by-exam')}>
                    <InputNumber name="nQuestionsByExam" />
                    <p className={cx('questions-by-exam-label')}>
                      {t('questions-by-exam-label')}
                    </p>
                  </div>
                )}
                <Switch
                  className={cx('field')}
                  label={t('review-label')}
                  name="isExamReviewEnabled"
                />
              </div>
              <div className={cx('content')}>
                <p className={cx('section-title')}>
                  {t('section-instructions')}
                </p>
                <InputRich
                  className={cx('field')}
                  label={t('instructions-label')}
                  name="examInstructions"
                />
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default Settings;
