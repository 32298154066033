import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import CourseItem from '../CourseItem/CourseItem';
import styles from './Standalone.module.scss';

const cx = classNames.bind(styles);

const Standalone = ({ courses }) => {
  const filteredCourses =
    courses.filter((course) => course.folderId === null) || [];

  return (
    <div className={'container'}>
      <Droppable droppableId="dashboard" type="course">
        {(providedCourses, snapshotCourses) => (
          <div
            className={cx('droppable', {
              isDraggingOver: snapshotCourses.isDraggingOver,
            })}
            ref={providedCourses.innerRef}
            {...providedCourses.droppableProps}
          >
            {filteredCourses.map((course, index) => (
              <CourseItem
                className={cx('course')}
                course={course}
                index={index}
                key={course.id}
                folderId=""
              />
            ))}
            {providedCourses.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

Standalone.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Standalone;
