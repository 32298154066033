import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { mutationAppDashboardDisplayStyle } from 'gql/mutations/app';
import { useMutationCourseCreate } from 'gql/mutations/course';
import { mutationEditorFolderUpdate } from 'gql/mutations/editor';
import { useMutationFolderCreate } from 'gql/mutations/folder';
import { useQueryLocalApp } from 'gql/queries/local';
import useSiteContext from 'hooks/useSiteContext';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit//Menu';

import styles from './Actions.module.scss';

const cx = classNames.bind(styles);

const Actions = ({
  filter,
  handleCourseJoinOpen,
  handleFilterSet,
  isEmpty,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard',
  });

  const mutationFolderCreate = useMutationFolderCreate();

  const mutationCourseCreate = useMutationCourseCreate();

  const { isEditionDisabled } = useSiteContext();

  const {
    data: {
      app: { dashboardDisplayStyle },
    },
  } = useQueryLocalApp();

  const [isCourseCreating, setCourseCreating] = useState(false);

  async function handleCourseCreate() {
    try {
      setCourseCreating(true);
      const {
        data: { courseCreate },
      } = await mutationCourseCreate({
        title: t('new-course-title'),
      });
      history.push(`/course/${courseCreate.id}/`);
      trackEvent('course', 'create');
    } catch (err) {
      reportError('course', 'create', err);
    } finally {
      setCourseCreating(false);
    }
  }

  function handleDisplayStyleChange() {
    mutationAppDashboardDisplayStyle(
      dashboardDisplayStyle === 'list' ? 'grid' : 'list',
    );
  }

  async function handleFolderCreate() {
    try {
      const {
        data: { folderCreate },
      } = await mutationFolderCreate({
        title: t('new-folder-title'),
      });
      mutationEditorFolderUpdate(folderCreate);
      trackEvent('folder', 'create');
    } catch (err) {
      reportError('folder', 'create', err);
    }
  }

  return (
    <div className={cx('section-header')}>
      <div className={cx('actions')}>
        {!isEditionDisabled && (
          <>
            <button
              className={cx('action-create')}
              data-testid="create-course-button"
              onClick={handleCourseCreate}
              type="button"
            >
              <div className={cx('icon-container')}>
                <Icon
                  className={cx('icon')}
                  name={isCourseCreating ? 'spinner' : 'plus'}
                />
              </div>
              {t('create-course')}
            </button>
            <button
              className={cx('action-join')}
              data-testid="join-course-button"
              icon="user-plus"
              id="course-join-button"
              onClick={handleCourseJoinOpen}
              type="button"
            >
              <div className={cx('icon-container', 'icon-container-join')}>
                <Icon className={cx('icon', 'icon-join')} name="book-medical" />
              </div>
              {t('join-course')}
            </button>
          </>
        )}
      </div>
      <div className={cx('options')}>
        <Menu
          className={cx('plus-menu')}
          toggle={<Button icon="plus" intent="primary" />}
        >
          <MenuItem icon="plus" onClick={handleCourseCreate}>
            {t('create-course')}
          </MenuItem>
          <MenuItem icon="user-plus" onClick={handleCourseJoinOpen}>
            {t('join-course')}
          </MenuItem>
          {!isEmpty && (
            <MenuItem icon="folder-plus" onClick={handleFolderCreate}>
              {t('add-folder')}
            </MenuItem>
          )}
        </Menu>
        {!isEditionDisabled && (
          <Menu
            toggle={
              <Button
                icon="sliders-h"
                intent={filter !== 'all' ? 'primary' : 'white'}
                tooltip={t('filter')}
              />
            }
          >
            <MenuItem
              isActive={filter === 'all'}
              onClick={() => handleFilterSet('all')}
            >
              {t('filter-all')}
            </MenuItem>
            <MenuItem
              isActive={filter === 'teacher'}
              onClick={() => handleFilterSet('teacher')}
            >
              {t('filter-teacher')}
            </MenuItem>
            <MenuItem
              isActive={filter === 'student'}
              onClick={() => handleFilterSet('student')}
            >
              {t('filter-student')}
            </MenuItem>
          </Menu>
        )}
        <Button
          className={cx('display')}
          icon={dashboardDisplayStyle === 'list' ? 'th' : 'list'}
          intent="white"
          onClick={handleDisplayStyleChange}
          tooltip={
            dashboardDisplayStyle === 'list'
              ? t('display-grid')
              : t('display-list')
          }
        />
        {dashboardDisplayStyle === 'list' && !isEmpty && !isEditionDisabled && (
          <Button
            className={cx('display', 'folder-button')}
            icon="folder-plus"
            intent="white"
            onClick={handleFolderCreate}
            tooltip={t('add-folder')}
          />
        )}
      </div>
    </div>
  );
};

Actions.propTypes = {
  filter: PropTypes.oneOf(['all', 'student', 'teacher']),
  handleCourseJoinOpen: PropTypes.func.isRequired,
  handleFilterSet: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default Actions;
