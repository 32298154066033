import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Files from '../Files/Files';
import Comments from '../Comment/Comments/Comments';
import ChapterCard from './ChapterCard';
import Questions from './Questions';
import styles from './CourseContent.module.scss';

const cx = classNames.bind(styles);

const CourseContent = ({
  className,
  course,
  hasComments,
  isQuestionBank,
  t,
}) => (
  <>
    <div className={className}>
      <h2
        id="course-content-title"
        className={cx('title', { 'title-question-bank': isQuestionBank })}
      >
        {t('Shared/CourseContent/title')}
      </h2>
      {course.files && (
        <Files course={course} isQuestionBank={isQuestionBank} />
      )}
      {course.chapters.map((chapter) => (
        <ChapterCard
          chapter={chapter}
          course={course}
          className={cx('chapter')}
          key={chapter.id}
        />
      ))}
      <Questions chapterId={null} course={course} />
    </div>
    {hasComments && <Comments course={course} />}
  </>
);

CourseContent.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  hasComments: PropTypes.bool.isRequired,
  isQuestionBank: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CourseContent.defaultProps = {
  className: undefined,
};

export default CourseContent;
