import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button/Button';
import Icon from 'uikit/Icon/Icon';

import ChapterProgress from './ChapterProgress/ChapterProgress';
import styles from './Chapters.module.scss';

const cx = classNames.bind(styles);

const Chapters = ({
  className = null,
  chapters,
  course,
  isStudent = false,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/Progress/Chapters',
  });

  const [isSectionOpen, setIsSectionOpen] = useState(false);

  const [shouldShowMore, setShouldShowMore] = useState(false);

  function handleSectionToggle() {
    setIsSectionOpen(!isSectionOpen);
  }

  function handleShowMoreToggle() {
    setShouldShowMore(!shouldShowMore);
  }

  const displayedChapters = chapters.filter((_, i) => i <= 5 || shouldShowMore);

  return (
    <div className={cx('container', className, { isSectionOpen })}>
      <button
        className={cx('header')}
        onClick={handleSectionToggle}
        type="button"
      >
        <div className={cx('title-container')}>
          <Icon className={cx('title-icon')} name="list-ol" />
          <h2 className={cx('title')}>
            {t(isStudent ? 'title-student' : 'title')}
          </h2>
          <div className={cx('count')}>{chapters.length}</div>
        </div>
        <Icon
          className={cx('toggle-button')}
          name={isSectionOpen ? 'chevron-up' : 'chevron-down'}
        />
      </button>
      {isSectionOpen && (
        <div className={cx('chapters-container')}>
          <div className={cx('chapters')}>
            {displayedChapters.map((chapter) => (
              <ChapterProgress
                chapter={chapter}
                course={course}
                isStudent={isStudent}
                key={chapter.id}
              />
            ))}
          </div>
          {chapters.length > 5 && (
            <Button
              className={cx('chapters-toggle')}
              onClick={handleShowMoreToggle}
            >
              {t(shouldShowMore ? 'show-less' : 'show-more')}
              <Icon
                className={cx('chapters-toggle-icon')}
                name={shouldShowMore ? 'chevron-up' : 'chevron-down'}
              />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

Chapters.propTypes = {
  className: PropTypes.string,
  chapters: PropTypes.arrayOf(PropTypes.object).isRequired,
  course: PropTypes.object.isRequired,
  isStudent: PropTypes.bool,
};

export default Chapters;
